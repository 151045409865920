import React from "react"
import axios from "../../axios"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/scss"
import "./SimilarHouses.scss"

const initialState = {
	toPostData: null,
	data: null,
	loading: true,
}

function reducer(state, action) {
	switch (action.type) {
		case "SET_TO_POST_DATA":
			return { ...state, toPostData: action.payload }
		case "SET_DATA":
			return { ...state, data: action.payload, loading: false }
		default:
			return state
	}
}

export const SimilarHouses = (props) => {
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const { data, loading } = state
	const [slidesPerView, setSlidesPerView] = React.useState(3)

	React.useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth
			if (width > 1025) {
				setSlidesPerView(3)
			} else {
				setSlidesPerView(1.2)
			}
		}

		window.addEventListener("resize", handleResize)
		handleResize()

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	React.useEffect(() => {
		if (props.area && props.houseType) {
			const newToPostData = { projArea: props.area, projType: props.houseType }
			dispatch({ type: "SET_TO_POST_DATA", payload: newToPostData })

			axios
				.post("/projects/similar", newToPostData)
				.then((res) => {
					dispatch({ type: "SET_DATA", payload: res.data })
				})
				.catch((err) => {
					console.warn(err)
					alert("Ошибка при получении похожих проектов")
				})
		}
	}, [props.area, props.houseType])

	return (
		<div className="similar">
			<h1 className="similar__header">ПОХОЖИЕ ПРОЕКТЫ</h1>

			<div className="similar__wrapper">
				<Swiper className="swiper-similar" slidesPerView={slidesPerView} spaceBetween={10} speed={500}>
					{!loading &&
						(data.length > 3
							? data.slice(0, -1).map((obj, cnt) => (
									<SwiperSlide key={cnt} className="swiper-similar-slide">
										<Link to={`/projects/${obj.id}`} className="similar__link">
											<div className="similar__image">
												<img src={obj.images[0]} alt={obj.title + " " + cnt} className="similar-img" />
											</div>

											<p className="similar__title">
												{obj.houseType === "Баня" ? "Баня, " : ""}"{obj.title}" <span className="v-divider"> | </span>
												{obj.area} м<sup>2</sup> <span className="v-divider"> | </span>
												{obj.floorsCount} {obj.floorsCount === 1 ? "этаж" : "этажа"}{" "}
											</p>

											<p className="similar__price">
												{/* {obj.price} */}
												По запросу
											</p>
										</Link>
									</SwiperSlide>
							  ))
							: data.map((obj, cnt) => (
									<SwiperSlide key={cnt}>
										<Link to={`/projects/${obj.id}`} className="similar__link">
											<div className="similar__image">
												<img src={obj.images[0]} alt={obj.title + " " + cnt} className="similar-img" />
											</div>

											<p className="similar__title">
												{obj.houseType === "Баня" ? "Баня, " : ""}"{obj.title}" <span className="v-divider"> | </span>
												{obj.area} м<sup>2</sup> <span className="v-divider"> | </span>
												{obj.floorsCount} {obj.floorsCount === 1 ? "этаж" : "этажа"}{" "}
											</p>

											<p className="similar__price">
												{/* {obj.price} */}
												По запросу
											</p>
										</Link>
									</SwiperSlide>
							  )))}
				</Swiper>
			</div>
		</div>
	)
}

export default SimilarHouses
