import React from "react"
import BuildBlock from "./BuildBlock/BuildBlock"
import { MyContext } from "../../Context/Context"

import "./WeBuild.scss"

import wooden from "../../img/h-wooden.png"
import woodenWebp from "../../img/h-wooden.webp"

import stone from "../../img/h-stone.png"
import stoneWebp from "../../img/h-stone.webp"

import banya from "../../img/h-banya.png"
import banyaWebp from "../../img/h-banya.webp"

import combined from "../../img/h-combined.png"
import combinedWebp from "../../img/h-combined.webp"

import typical from "../../img/h-typical.png"
import typicalWebp from "../../img/h-typical.webp"

export default function WeBuild() {
	const { childrenSetHouseType, updateFilterValue, childrenSetCategory, useWebP } = React.useContext(MyContext)

	const woodenType = () => {
		childrenSetHouseType(["Деревянный дом"])
		updateFilterValue({ houseType: "Деревянный дом" })
	}

	const stoneType = () => {
		childrenSetHouseType(["Каменный дом"])
		updateFilterValue({ houseType: "Каменный дом" })
	}

	const combinedType = () => {
		childrenSetHouseType(["Комбинированный дом"])
		updateFilterValue({ houseType: "Комбинированный дом" })
	}

	const bathType = () => {
		childrenSetHouseType(["Баня"])
		updateFilterValue({ houseType: "Баня" })
	}

	const typicalCategory = () => {
		childrenSetCategory(["Типовые проекты"])
		updateFilterValue({ category: "Типовые проекты" })
	}
	return (
		<section className="we-build">
			<h1 className="title">Мы строим</h1>

			<div className="we-build__wrapper mb-16">
				<BuildBlock title="Деревянные дома" img={useWebP ? woodenWebp : wooden} onLinkClick={woodenType} />
				<BuildBlock title="Каменные дома" img={useWebP ? stoneWebp : stone} onLinkClick={stoneType} />
				<BuildBlock title="Бани" img={useWebP ? banyaWebp : banya} onLinkClick={bathType} />
				<BuildBlock title="Комбинированные дома" img={useWebP ? combinedWebp : combined} onLinkClick={combinedType} />
				<BuildBlock title="Типовые проекты" img={useWebP ? typicalWebp : typical} onLinkClick={typicalCategory} />
			</div>
		</section>
	)
}
