import React from "react"
import { fetchGallery } from "../../redux/slices/gallery"
import { useDispatch, useSelector } from "react-redux"

import { GalleryBlock } from "../../components"

export const Gallery = () => {
	const [isGalleryLoading, setGalleryLoading] = React.useState(true)

	const dispatch = useDispatch()
	React.useEffect(() => {
		dispatch(fetchGallery())
		window.scrollTo(0, 0)
	}, [])

	const { gallery } = useSelector((state) => state.gallery)

	React.useEffect(() => {
		setGalleryLoading(gallery.status === "loading")
		console.log(gallery)
	}, [gallery])

	return (
		<div className="galleries lg:container py-4 mx-4 lg:mx-auto">
			<h2 className="py-8 uppercase text-xl md:text-3xl lg:text-4xl font-bold text-colorMain">РЕАЛИЗОВАННЫЕ ПРОЕКТЫ</h2>

			<div className="flex justify-start flex-wrap gap-8">
				{!isGalleryLoading &&
					gallery.items.map((obj) => <GalleryBlock isLoading={isGalleryLoading} key1={obj._id} id={obj.id} title={obj.title} images={obj.images} />)}
			</div>
		</div>
	)
}

export default Gallery
