import React from "react"
import axios from "../../axios"

import { MyContext } from "../../Context/Context"

import { AiOutlineStar, AiFillStar } from "react-icons/ai"

const LikeButton = ({ projectId, projectLikes, likes }) => {
	const [likeCount, setLikeCount] = React.useState(0)
	const [liked, setLiked] = React.useState(false)
	const { userId } = React.useContext(MyContext)

	React.useEffect(() => {
		if (projectLikes) {
			setLikeCount(projectLikes)
		}

		if (likes.includes(userId)) setLiked(true)
	}, [])

	const handleLike = async () => {
		try {
			const response = await axios.post(`/projects/${projectId}/like`, { userId })

			setLikeCount(response.data.likeCount)
			response.data.likes.includes(userId) ? setLiked(true) : setLiked(false)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<button onClick={handleLike} className={`text-black text-xl flex justify-center items-center gap-2 ${liked ? "liked" : ""}`}>
			<span>
				{liked ? (
					<AiFillStar className="text-colorMain hover:text-colorDarkGray duration-300 transition-all ease-out" />
				) : (
					<AiOutlineStar className="text-colorDarkGray hover:text-colorMain duration-300 transition-all ease-out" />
				)}
			</span>
			<span className="text-white"> {likeCount}</span>
		</button>
	)
}

export default LikeButton
