import React from "react"
import { Link } from "react-router-dom"

import "./style.scss"
import designingsImg1 from "../../img/designings2.webp"
import designingsImg2 from "../../img/designings1-2.webp"
import { BsCheckCircleFill } from "react-icons/bs"

export const SketchDesigning = () => {
	return (
		<div className="designings container py-4 mx-auto">
			<h1 className="designings__title">ЭСКИЗНОЕ ПРОЕКТИРОВАНИЕ</h1>

			<div className="designings__wrapper">
				<h3 className="designings__header">
					Высокие стандарты качества, современная архитектура и ответственность за выполненные проекты – основные
					принципы, применяемые при проектировании деревянных домов от компании «ArteMadera»
				</h3>

				<h2 className="designings__sub-title">ИНДИВИДУАЛЬНОЕ ПРОЕКТИРОВАНИЕ ДОМОВ ИЗ КЛЕЕНОГО БРУСА</h2>

				<p className="designings__text">
					Эскизный проект деревянного дома – это один из первых шагов на пути к реализации мечты иметь собственный дом,
					созданный по неповторимому дизайну. Воспользовавшись услугами наших архитекторов, у Вас есть возможность
					выбрать уже готовый проект деревянного дома, или же создать свой собственный с помощью специалистов нашего
					архитектурного бюро.
				</p>

				<p className="designings__text">
					Лучшие архитекторы с огромным опытом работы в области проектирования помогут обрести современный деревянный
					дом Вашей мечты. Эскизное проектирование дома будет разрабатывается с учетом всех пожеланий заказчика.
				</p>

				<div className="designings__image">
					<img src={designingsImg1} alt="ЭСКИЗНОЕ ПРОЕКТИРОВАНИЕ" loading="lazy" />
				</div>

				<h2 className="designings__sub-title">ПОЧЕМУ ВЫБИРАЮТ ЭСКИЗНОЕ ПРОЕКТИРОВАНИЕ</h2>

				<ul className="designings__list">
					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						<span>
							По проектам архитекторов компании «ArteMadera» построено более 600 домов (коттеджей, бань, садовых и
							дачных домов) по России, а также за ее пределами.
						</span>
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Высококвалифицированное комплексное проектирование деревянных домов из клееного бруса выполняется
						профессиональной командой архитекторов, дизайнеров, конструкторов и инженеров.
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Индивидуальный подход наших специалистов к заказчикам предоставляет возможность учитывать полностью все
						пожелания наших клиентов.
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Все проекты деревянных домов, разработанные архитекторами компании «ArteMadera» — это в первую очередь
						уникальная архитектурная разработка, результат работы опытных архитекторов и заказчика.
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Анализ земельного участка нашими архитекторами избавит заказчика в дальнейшем от ряда сопутствующих проблем
						и дополнительных капиталовложений для их разрешения.
					</li>
				</ul>

				<h2 className="designings__sub-title">КАКИЕ РАБОТЫ ВЫПОЛНЯЮТСЯ НА ЭТАПЕ ВЫПОЛНЕНИЯ ЭСКИЗНОГО ПРОЕКТА?</h2>

				<p className="designings__text">
					На этапе эскизного проектирования наши архитекторы согласовывают все разделы будущего архитектурного проекта
					деревянного дома:
				</p>

				<ul className="designings__list">
					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Утверждение размещения планов этажей будущего дома (добавление или перемещение планов помещений, внесение
						изменений их расположения и размеров)
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Согласование и утверждение фасадов деревянного дома (смещение или изменение размеров дома, изменения в
						конфигурации крыши, высоты этажей добавление окон, дверей, детальная визуализация 3D модели будущего
						деревянного дома)
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Согласование и утверждение индивидуальных цветовых решений, внешнего вида, кровли и материалов стен
						деревянного дома.
					</li>
				</ul>

				<p className="designings__text">
					После согласования и утверждения заказчиком планов этажей будущего дома, фасадов и индивидуальных цветовых
					решений специалисты нашего архитектурного бюро подготовят для Вас Альбом эскизного проекта.
				</p>

				<div className="designings__image">
					<img src={designingsImg2} alt="Design-2" loading="lazy" />
				</div>

				<h2 className="designings__sub-title">АРХИТЕКТОРЫ И ПОРТФОЛИО ПРОЕКТОВ</h2>

				<p className="designings__text">
					Наши архитекторы с радостью помогут Вам воплотить в жизнь любые Ваши идеи по проектированию дома и
					обустройству Вашего участка. Специалисты нашего архитектурного бюро обладают большим опытом в проектировании
					деревянных домов, а также реконструкции зданий и сооружений из клееного бруса.
				</p>

				<Link to="/projects" className="designings__link" target="_blank">
					&gt;&gt;&gt; Смотреть проекты наших архитекторов
				</Link>

				<h2 className="designings__sub-title">УСЛУГИ ПРОЕКТИРОВАНИЯ:</h2>

				<p className="designings__text designings__text-center">
					Эскизный архитектурный проект – стоимость от 6 $ за кв. м.
				</p>

				<p className="designings__text designings__text-center">Рабочий проект – стоимость от 24 $ за кв. м.</p>

				<div className="mt-16">
					<b>
						<p className="designings__text designings__text-center">
							Выберите понравившийся Вам проект деревянного дома на нашем сайте.
						</p>

						<p className="designings__text designings__text-center">
							На его основе мы разработаем индивидуальное эскизное решение для Вас.
						</p>

						<p className="designings__text designings__text-center">
							Если Вы не нашли подходящий проект, свяжитесь с нами — мы предложим Вам альтернативу!
						</p>
					</b>
				</div>

				<div className="designings__bottom-links">
					<Link to="/projects" className="bottom-link more-houses-link" target="_blank">
						Больше проектов
					</Link>

					<Link to="#" className="bottom-link show-gallery-link" target="_blank">
						Смотреть галерею
					</Link>
				</div>
			</div>
		</div>
	)
}

export default SketchDesigning
