import React from "react"
import { Link } from "react-router-dom"
import "./Article.scss"

export const Article = (props) => {
	return (
		<div className="article">
			<div className="article__image">
				<Link className="article__wrapper-link" to={`/blog/${props.link}`}>
					<img src={props.img} alt={props.img} className="article-img" />
				</Link>
			</div>

			<div className="article__title">{props.title}</div>

			<div className="article__text">{props.text}</div>

			<Link className="article__btn" to={`/blog/${props.link}`}>
				<button>Подробнее</button>
			</Link>
		</div>
	)
}

export default Article
