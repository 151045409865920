import React from "react"
import InputMask from "react-input-mask"
import { Link } from "react-router-dom"
import axios from "../../axios"

import "./DefForm.scss"
import PopupAnswer from "../PopupAnswer/PopupAnswer"

export const DefForm = (props) => {
	const [name, setName] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [phone, setPhone] = React.useState("")
	const [unmaskedPhone, setUnmaskedPhone] = React.useState("")
	const [nameError, setNameError] = React.useState("")
	const [phoneError, setPhoneError] = React.useState("")
	const [emailError, setEmailError] = React.useState("")
	const [disableButton, setDisableButton] = React.useState(false)
	const [serverResponse, setServerResponse] = React.useState("")
	const [isPopupOpen, setIsPopupOpen] = React.useState(false)

	const validateName = () => {
		if (name === "") {
			setNameError("Пожалуйста, введите ваше имя")
		} else {
			setNameError("")
		}
	}

	const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu

	const validateEmail = () => {
		if (email.length > 1) {
			const emailTest = EMAIL_REGEXP.test(email)
			!emailTest && setEmailError("Неправильный ввод почты")
			emailTest && setEmailError("")
		} else setEmailError("")
	}

	React.useEffect(() => {
		setUnmaskedPhone(phone.replace(/\s|\(|\)/g, ""))
	}, [phone])

	const validatePhone = () => {
		if (!unmaskedPhone) {
			setPhoneError("Пожалуйста, введите ваш номер телефона")
		} else if (unmaskedPhone.length !== 12) {
			setPhoneError("Неверно введен номер")
		} else {
			setPhoneError("")
		}
	}

	//Отправка данных формы, с дальнейшей проверкой на сервере
	const handleSubmit = async (e) => {
		e.preventDefault()
		validateName()
		validatePhone()
		validateEmail()

		const formData = {
			name: name,
			phone: phone,
			email: email,
		}

		// Выполнение HTTP-запроса для отправки данных на сервер
		const { data } = await axios.post("/form/fullProject", formData)
		setServerResponse(data)
		setIsPopupOpen(true)
		document.body.style.overflow = "hidden"
	}

	//Закрыть попап
	const handleClosePopup = () => {
		setIsPopupOpen(false)
		document.body.style.overflow = "auto"
	}

	//Отключить кнопку, если чекбокс неактивен
	const handleCheckPolicy = (event) => {
		event.checked = !event.checked

		const isChecked = event.target.checked
		setDisableButton(!isChecked)
	}

	return (
		<form className="defForm" id="defForm" onSubmit={handleSubmit}>
			<h1 className="defForm__title">{props.title}</h1>
			<h2 className="defForm__sub-title">Оставляйте заявку и мы ответим на все интересующие вас вопросы</h2>

			<div className="defForm__wrapper">
				<div className="defForm__block">
					<label htmlFor="fio">Имя</label>
					<input
						type="text"
						name="fio"
						id="fio"
						className="defForm__input"
						onChange={(e) => {
							setName(e.target.value)
						}}
						placeholder="Имя Фамилия"
					/>
					{nameError && <span className="error">{nameError}</span>}
				</div>

				<div className="defForm__block">
					<label htmlFor="tel">Телефон</label>
					<InputMask
						mask="+7 (999) 999 99 99"
						placeholder="+7 (123) 456 78 90"
						type="tel"
						name="tel"
						id="tel"
						maskChar={" "}
						className="defForm__input"
						onChange={(e) => {
							setPhone(e.target.value)
						}}
					/>
					{phoneError && <span className="error">{phoneError}</span>}
				</div>

				<div className="defForm__block">
					<label htmlFor="email">
						E-Mail <span className="text-xs font-normal text-gray-400">(по желанию)</span>
					</label>
					<input
						type="text"
						name="email"
						id="email"
						className="defForm__input"
						onChange={(e) => {
							setEmail(e.target.value)
						}}
						placeholder="example@mail.ru"
					/>
					{emailError && <span className="error">{emailError}</span>}
				</div>
			</div>

			<button disabled={disableButton} type="submit" className="defForm__btn bg-colorMain">
				Отправить
			</button>

			<div className="defForm__check">
				<input type="checkbox" name="policy" id="policy" defaultChecked onChange={handleCheckPolicy} className="defForm__policy" />
				<label className="policy-label" htmlFor="policy">
					{" "}
					Я согласен на
					<Link to='/obrabotka-personalnykh-dannykh/' className="underline"> обработку персональных данных</Link>
				</label>
			</div>

			{isPopupOpen && <PopupAnswer text={serverResponse} onClosePopup={handleClosePopup} />}
		</form>
	)
}

export default DefForm
