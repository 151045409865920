import React from "react"
import InputMask from "react-input-mask"
import { Link } from "react-router-dom"
import axios from "../../axios"
import PopupAnswer from "../PopupAnswer/PopupAnswer"
import { IoCloseCircleOutline } from "react-icons/io5"

import { MdArrowRightAlt } from "react-icons/md"

import "./CallbackForm.scss"

export const CallbackForm = ({ formCloseClick }) => {
	const [phone, setPhone] = React.useState("")
	const [unmaskedPhone, setUnmaskedPhone] = React.useState("")
	const [phoneError, setPhoneError] = React.useState("")
	const [disableButton, setDisableButton] = React.useState(false)
	const [serverResponse, setServerResponse] = React.useState("")
	const [isPopupOpen, setIsPopupOpen] = React.useState(false)

	React.useEffect(() => {
		setUnmaskedPhone(phone.replace(/\s|\(|\)/g, ""))
	}, [phone])

	const validatePhone = () => {
		if (!unmaskedPhone) {
			setPhoneError("Пожалуйста, введите ваш номер телефона")
		} else if (unmaskedPhone.length !== 12) {
			setPhoneError("Неверно введен номер")
		} else {
			setPhoneError("")
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		validatePhone()

		const formData = {
			phone: phone,
		}

		// Выполнение HTTP-запроса для отправки данных на сервер
		const { data } = await axios.post("/form/callbackForm", formData)
		setServerResponse(data)
		setIsPopupOpen(true)
		document.body.style.overflow = "hidden"
	}

	//Закрыть попап
	const handleClosePopup = () => {
		setIsPopupOpen(false)
		document.body.style.overflow = "auto"
	}

	const handleCheckPolicy = (event) => {
		event.checked = !event.checked

		const isChecked = event.target.checked
		setDisableButton(!isChecked)
	}

	const formRef = React.useRef(null)

	React.useEffect(() => {
		const handleClickOutside = (event) => {
			if (formRef.current && !formRef.current.contains(event.target)) {
				formCloseClick(false)
			}
		}

		document.addEventListener("mousedown", handleClickOutside)

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [])

	return (
		<div className="cbForm__wrapper">
			<form ref={formRef} action="" className="cbForm" id="cbForm" onSubmit={handleSubmit}>
				<IoCloseCircleOutline
					onClick={formCloseClick}
					className="absolute top-4 right-4 text-3xl fill-colorMain hover:opacity-50 hover:cursor-pointer"
				/>
				<h2 className="cbForm__title">Мы вам перезвоним</h2>
				<p className="cbForm__text">В ближайшее время с вами свяжется персональный менеджер и ответит на интересующие вопросы</p>

				<InputMask
					mask="+7 (999) 999 99 99"
					placeholder="+7 (123) 456 78 90"
					type="tel"
					name="tel"
					id="tel"
					maskChar={" "}
					className="comForm__input"
					onChange={(e) => {
						setPhone(e.target.value)
					}}
				/>
				{phoneError && <span className="error">{phoneError}</span>}

				<button disabled={disableButton} type="submit" className="cbForm__btn bg-colorMain flex justify-center items-center mt-4 sm:mt-8">
					<span>Заказать звонок</span> <MdArrowRightAlt className="text-3xl ml-2" />
				</button>

				<div className="cbForm__check">
					<input type="checkbox" name="policy" id="policy" defaultChecked onChange={handleCheckPolicy} className="cbForm__policy" />
					<label className="policy-label" htmlFor="policy">
						{" "}
						Я согласен на
						<Link to="/obrabotka-personalnykh-dannykh/" className="underline">
							{" "}
							обработку персональных данных
						</Link>
					</label>
				</div>

				{isPopupOpen && <PopupAnswer text={serverResponse} onClosePopup={handleClosePopup} />}
			</form>
		</div>
	)
}

export default CallbackForm
