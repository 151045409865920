import React from "react"

import "./Service.scss"
import { Link } from "react-router-dom"

export const Service = (props) => {
	return (
		<Link className="service-link" to={props.link} target={props.link == "/services/designing" ? "_self" : "_blank"}>
			<div className="service">
				<div className="service__header">{props.header}</div>
				<div className="service__image">
					<img src={props.img} alt={props.header} />
				</div>
			</div>
		</Link>
	)
}

export default Service
