import React from "react"
import "./blogs.scss"

export const DomaFahverk = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="blogs container mx-auto px-8">
			<div className="blogs__wrapper">
				<h1 className="blogs__title">ЧТО ТАКОЕ ФАХВЕРКОВЫЙ ДОМ?</h1>

				<p className="blogs__text">
					Если вы мечтаете о современном, экологичном доме с невероятным
					дизайном и панорамным остеклением — технология фахверковых домов
					станет воплощением ваших задумок в реальность. Первое, чем подкупают
					фахверковые дома — своим дизайном. Но это не все, чем может подкупить
					фахверк. Давайте подробнее разберем эту технологии, а именно ее
					преимущества и недостатки.
				</p>

				<h2 className="blogs__sub-title">ПРЕИМУЩЕСТВА ФАХВЕРКА</h2>

				<h3 className="blogs__sub-header">✓ СОВРЕМЕННЫЙ ДИЗАЙН</h3>

				<p className="blogs__text">
					Если мы посмотрим на дачные массивы — мы вряд будем восхищаться
					дизайном коттеджей. Половина загородных домов представляет собой
					постройки 70-80-х годов, где однообразный серый кирпич разбавляется
					разве что маленькими окошками. Более современные дома не всегда
					отличаются от старых приятной эстетикой — это те же кирпичные дома,
					только кирпич отличается цветом и размеры дома немного больше. Но
					худшее, что можно встретить за городом — разноцветные утепленные
					фасады, разноцветные крыши и полное отсутствие какой-либо эстетики.
				</p>

				<p className="blogs__text">
					Фахверковые дома отличаются кардинально: окна в пол, красивое
					сочетание стекла и дерева, функциональный дизайн. Вы можете посмотреть
					проекты деревянных домов на нашем сайте и убедиться в том, что внешний
					вид дома имеет большое значение.
				</p>

				<h3 className="blogs__sub-header">✓ СРОК ЭКСПЛУАТАЦИИ</h3>

				<p className="blogs__text">
					Наша компания дает 50 лет гарантии на фахверковый дом. Основные
					материалы дома — стекло и клееный брус. Стекло, как известно, очень
					долговечно, а клееный брус не гниет и не подвергается атакам насекомых
					из-за клея в составе.
				</p>

				<h3 className="blogs__sub-header">✓ УСЛОВИЯ ЭКСПЛУАТАЦИИ</h3>

				<p className="blogs__text">
					И жарким летом, и в морозную зиму фахверковый дом одинаково хорошо
					сохраняет температуру помещения за счет современных многослойных
					стеклопакетов. Поэтому технология применяется в самых разных регионах,
					вне зависимости от климатических особенностей.
				</p>

				<h3 className="blogs__sub-header">
					✓ ПОСТРОИТЬ ДЕРЕВЯННЫЙ ДОМ — БЫСТРО
				</h3>

				<p className="blogs__text">
					Несколько месяцев — и дом готов. Не учитывая фундамент, сам дом может
					быть построен всего за два месяца. При этом, ждать пока дом даст
					усадку не нужно — фахверковая технология предполагает минимальную
					усадку 1-2 процента, поэтому отделка дома производится сразу же.
				</p>

				<h3 className="blogs__sub-header">✓ ЭКОНОМИЯ НА ПЛОЩАДИ ОТДЕЛКИ</h3>

				<p className="blogs__text">
					Стекло не требует дополнительных отделочных работ, а брус клееный
					достаточно покрасить, если вы не хотите тратить дополнительные деньги
					и время на дизайн и отделочные работы.
				</p>

				<h3 className="blogs__sub-header">✓ ЭКОНОМИЯ НА ФУНДАМЕНТЕ</h3>

				<p className="blogs__text">
					Фахверковый дом — относительно легкий, поэтому нет нужды в глубоком и
					сложном фундаменте. Сокращая требования к фундаменту, сокращается и
					бюджет строительства дома.
				</p>

				<h2 className="blogs__sub-title">
					НЕДОСТАТКИ ФАХВЕРКА ВЕСЬМА НЕСУЩЕСТВЕННЫ
				</h2>

				<h3 className="blogs__header">ЦЕНА НА ФАХВЕРК</h3>

				<p className="blogs__text">
					Еще недавно, такие дома в России редко встречались, а построить
					деревянный дом по технологии фахверк стоило очень дорого. Этот главный
					недостаток помешал фахверковым домам распространиться и занять нишу
					наиболее популярной технологии строительства. Сегодня цена на
					технологию снизилась в несколько раз, поэтому фахверковые дома стали
					гораздо доступнее, а интерес к ним и спрос растет с каждым днем.
				</p>

				<h3 className="blogs__header">ВЫСОТА ПОТОЛКОВ</h3>

				<p className="blogs__text">
					Относительно низкие потолки могут стать для кого-то недостатком
					фахверка. Как правило, такие дома строят с высотой потолков 2,5 метра.
					Индивидуальные сложные проекты могут быть построены и с более высокими
					потолками, но это, скорее, исключения. Тем не менее, за счет
					естественного освещения, в том числе иногда осуществляемого
					дополнительными окнами в крыше, внутри дома вы не будете чувствовать
					себя скованно. Естественный свет и панорамные окна добавляют воздуха
					помещениям, а дизайн интерьера поможет подчеркнуть это свободное
					пространство.
				</p>
			</div>
		</div>
	)
}

export default DomaFahverk
