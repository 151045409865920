import React from "react"

import { Link, useParams } from "react-router-dom"
import axios from "../../axios"
import Lightbox from "yet-another-react-lightbox"
import Captions from "yet-another-react-lightbox/plugins/captions"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import "yet-another-react-lightbox/plugins/captions.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"
import "yet-another-react-lightbox/styles.css"

import "./FullGallery.scss"

export const FullGallery = () => {
	const [data, setData] = React.useState(true)

	//Waiting for project load
	const [isLoading, setLoading] = React.useState(true)

	//Get project by ID
	const { id } = useParams()

	//For swiper lightbox
	const [lightboxIsOpen, setLightboxIsOpen] = React.useState(false)
	const [activeIndex, setActiveIndex] = React.useState(0)

	//Clink on image setting swiper lighbox
	const handleImageClick = (index) => {
		setActiveIndex(index)
		setLightboxIsOpen(true)
	}

	React.useEffect(() => {
		axios
			.get(`/gallery/${id}`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => {
				console.warn(err)
				alert("Ошибка при получении проекта")
			})

		window.scrollTo(0, 0)
	}, [])

	return (
		<div className="fullGallery">
			<h2 className="fullGallery__title">{data.title}</h2>
			<div className="fullGallery__grid">
				{!isLoading &&
					data.images.map((image, index, cnt = 1) => (
						<div key={index} className="fullGallery__image">
							<img
								src={image}
								alt={`${data.title} ${cnt++}`}
								loading="lazy"
								onClick={() => {
									handleImageClick(index)
								}}
							/>
						</div>
					))}

				<Lightbox
					open={lightboxIsOpen}
					close={() => setLightboxIsOpen(false)}
					slides={!isLoading && data.images.map((image) => ({ src: image }))}
					plugins={[Captions, Fullscreen, Thumbnails, Zoom]}
					index={activeIndex}
				/>
			</div>

		</div>
	)
}

export default FullGallery
