import React from "react"
import axios from "../axios"

export const MyContext = React.createContext()

//Для идентификатора пользователя
function generateUniqueId() {
	// Генерируем случайное число в диапазоне от 0 до 999999
	const randomNumber = Math.floor(Math.random() * 1000000)
	// Создаем уникальный идентификатор путем добавления текущей временной метки к случайному числу
	const uniqueId = `${Date.now()}${randomNumber}`
	return uniqueId
}

export const MyContextProvider = ({ children }) => {
	const [useWebP, setUseWebP] = React.useState(false)
	React.useEffect(() => {
		//Проверяет, поддерживает браузер WebP или нет
		const supportsWebP = () => {
			const canvas = document.createElement("canvas")
			if (!!(canvas.getContext && canvas.getContext("2d"))) {
				return canvas.toDataURL("image/webp").indexOf("data:image/webp") === 0
			}
			return false
		}

		const usesWebP = supportsWebP()
		const htmlElement = document.querySelector("html")

		if (usesWebP) {
			htmlElement.classList.add("webp-supported")
		} else {
			htmlElement.classList.add("webp-unsupported")
		}

		return () => {
			htmlElement.classList.remove("webp-supported", "webp-unsupported")
		}
	}, [])

	//Отправляем переменную на сервер, для загрузки WebP или других форматов
	const webpSupport = async () => {
		const isWebPSupp = document.documentElement.classList.contains("webp-supported")
		setUseWebP(isWebPSupp)
		try {
			await axios.post("/webp-support", { useWebP })
		} catch (error) {
			console.error(error)
		}
	}

	React.useEffect(() => {
		webpSupport()
		console.log(useWebP)
	}, [useWebP])

	const [filterValue, setFilterValue] = React.useState(() => {
		const storedFilterValue = localStorage.getItem("filterValue")
		return storedFilterValue ? JSON.parse(storedFilterValue) : {}
	})
	const [materials, setMaterials] = React.useState(() => {
		const storedMaterials = localStorage.getItem("materials")
		return storedMaterials ? JSON.parse(storedMaterials) : []
	})
	const [area, setArea] = React.useState(() => {
		const storedArea = localStorage.getItem("area")
		return storedArea ? JSON.parse(storedArea) : { min: 25, max: 570 }
	})
	const [houseType, setHouseType] = React.useState(() => {
		const storedHouseType = localStorage.getItem("houseType")
		return storedHouseType ? JSON.parse(storedHouseType) : []
	})
	const [category, setCategory] = React.useState(() => {
		const storedCategory = localStorage.getItem("category")
		return storedCategory ? JSON.parse(storedCategory) : []
	})
	const [oneFloor, setOneFloor] = React.useState(() => {
		const storedOneFloor = localStorage.getItem("oneFloor")
		return storedOneFloor ? JSON.parse(storedOneFloor) : 0
	})
	const [twoFloors, setTwoFloors] = React.useState(() => {
		const storedTwoFloors = localStorage.getItem("twoFloors")
		return storedTwoFloors ? JSON.parse(storedTwoFloors) : 0
	})

	//Для лайка
	const [userId, setUserId] = React.useState("")

	React.useEffect(() => {
		// Проверяем, есть ли уникальный идентификатор пользователя в localStorage
		const storedUserId = localStorage.getItem("userId")
		if (storedUserId) {
			setUserId(storedUserId)
		} else {
			// Если уникального идентификатора нет, генерируем новый и сохраняем его в localStorage
			const newUserId = generateUniqueId()
			setUserId(newUserId)
			localStorage.setItem("userId", newUserId)
		}
	}, [])

	function childrenSetArea(minArea, maxArea) {
		setArea({ min: minArea, max: maxArea })
	}

	function childrenSetMaterials(newValue) {
		setMaterials(newValue)
	}

	function childrenSetHouseType(newValue) {
		setHouseType(newValue)
	}

	function childrenSetCategory(newValue) {
		setCategory(newValue)
	}

	const updateFilterValue = (newValue) => {
		setFilterValue(newValue)
	}

	const childrenSetOneFloor = (newValue) => {
		setOneFloor(newValue)
	}

	const childrenSetTwoFloors = (newValue) => {
		setTwoFloors(newValue)
	}

	React.useEffect(() => {
		const storedFilterValue = localStorage.getItem("filterValue")
		if (storedFilterValue) {
			setFilterValue(JSON.parse(storedFilterValue))
		}

		const storedMaterials = localStorage.getItem("materials")
		if (storedMaterials) {
			setMaterials(JSON.parse(storedMaterials))
		}

		const storedHouseType = localStorage.getItem("houseType")
		if (storedHouseType) {
			setHouseType(JSON.parse(storedHouseType))
		}

		const storedCategory = localStorage.getItem("category")
		if (storedCategory) {
			setCategory(JSON.parse(storedCategory))
		}

		const storedArea = localStorage.getItem("area")
		if (storedArea) {
			setArea(JSON.parse(storedArea))
		}

		const storedOneFloor = localStorage.getItem("oneFloor")
		if (storedOneFloor) {
			setOneFloor(JSON.parse(storedOneFloor))
		}

		const storedTwoFloors = localStorage.getItem("twoFloors")
		if (storedTwoFloors) {
			setTwoFloors(JSON.parse(storedTwoFloors))
		}
	}, [])

	React.useEffect(() => {
		localStorage.setItem("filterValue", JSON.stringify(filterValue))
	}, [filterValue])

	React.useEffect(() => {
		localStorage.setItem("materials", JSON.stringify(materials))
	}, [materials])

	React.useEffect(() => {
		localStorage.setItem("houseType", JSON.stringify(houseType))
	}, [houseType])

	React.useEffect(() => {
		localStorage.setItem("category", JSON.stringify(category))
	}, [category])

	React.useEffect(() => {
		localStorage.setItem("area", JSON.stringify(area))
	}, [area])

	React.useEffect(() => {
		localStorage.setItem("oneFloor", JSON.stringify(oneFloor))
	}, [oneFloor])

	React.useEffect(() => {
		localStorage.setItem("twoFloors", JSON.stringify(twoFloors))
	}, [twoFloors])

	return (
		<MyContext.Provider
			value={{
				filterValue,
				updateFilterValue,
				materials,
				childrenSetMaterials,
				area,
				childrenSetArea,
				houseType,
				childrenSetHouseType,
				category,
				childrenSetCategory,
				userId,
				oneFloor,
				childrenSetOneFloor,
				twoFloors,
				childrenSetTwoFloors,
				useWebP,
			}}
		>
			{children}
		</MyContext.Provider>
	)
}
