import React from "react"
import { Link } from "react-router-dom"

import "./House.scss"
import LikeButton from "../TestingFeatures/Like"

export const House = (props) => {
	if (props.isLoading) return ""
	else
		return (
			<div className="relative mt-4 w-full md:w-[47%] text-colorDarkGray h-full xl:h-[500px] no-underline">
				<div className="house__likeCount absolute z-[20] right-5 top-5">
					<LikeButton projectId={props.id} projectLikes={props.likeCount} likes={props.likes} />
				</div>
				<Link to={`/projects/${props.id}`} className="house">
					{props.hasMansarda === true ? <div className="house__mansarda">+ Мансарда</div> : ""}
					<div className="house__image">
						<img src={props.images[0]} alt={`${props.houseType}, ${props.title}`} loading="lazy" />
					</div>

					<div className="house__data">
						{props.houseType === "Баня" ? "Баня, " : ""}
						{props.wallMaterial.length > 0 ? props.wallMaterial.join(" + ") : props.wallMaterial}, "{props.title}"<span className="v-divider">|</span>
						{props.area} м<sup>2</sup>
						<span className="v-divider">|</span>
						{props.floorsCount} &nbsp; {props.floorsCount === 1 ? "этаж" : "этажа"}
					</div>
					<div className="house__price">
						По запросу
					</div>
				</Link>
			</div>
		)
}
