import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

export const fetchHouses = createAsyncThunk('projecets/fetchHouses', async () => {
  const { data } = await axios.post('/projects')
  return data
})

const initialState = {
  houses: {
    items: [],
    status: 'loading'
  }
}

const housesSlice = createSlice({
  name: 'houses',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchHouses.pending]: (state) => {
      state.houses.items = []
      state.houses.status = 'loading'
    },
    [fetchHouses.fulfilled]: (state, action) => {
      state.houses.items = action.payload
      state.houses.status = 'loaded'
    },
    [fetchHouses.rejected]: (state) => {
      state.houses.items = []
      state.houses.status = 'error'
    }
  }
})

export const houseReducer = housesSlice.reducer