import React from "react"
import { useNavigate, Link } from "react-router-dom"
import axios from "../../axios"
import { MyContext } from "../../Context/Context"

import { Slider, FormGroup } from "@mui/material"
import { createTheme } from "@mui/material/styles"

import { IoCloseCircleOutline } from "react-icons/io5"
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im"

import "./Filter.scss"

//Нужно для изменения цвета MUI компонентов
const theme = createTheme({
	palette: {
		primary: {
			main: "#fbbe00",
		},
		secondary: {
			main: "#393939",
		},
	},
})

export const Filter = ({ onFilterData, onFilterLoad, isLoading }) => {
	const {
		filterValue,
		updateFilterValue,
		childrenSetHouseType,
		childrenSetCategory,
		houseType,
		category,
		materials,
		childrenSetMaterials,
		area,
		childrenSetArea,
		oneFloor,
		childrenSetOneFloor,
		twoFloors,
		childrenSetTwoFloors,
	} = React.useContext(MyContext)

	const [rangeSliderValue, setRangeSliderValue] = React.useState([area.min, area.max])

	//Для слайдера Площади
	let minDistance = 50

	//Чтобы кнопки слайдера Площади не приближались слишком близко (minDistance)
	const rangeSliderChange = (event, newValue, activeThumb) => {
		if (!Array.isArray(newValue)) {
			return
		}

		if (activeThumb === 0) {
			setRangeSliderValue([Math.min(newValue[0], rangeSliderValue[1] - minDistance), rangeSliderValue[1]])
		} else {
			setRangeSliderValue([rangeSliderValue[0], Math.max(newValue[1], rangeSliderValue[0] + minDistance)])
		}
	}

	//MUI Slider для onChangeCommited
	const handleRangeCommited = () => {
		if (area.min !== rangeSliderValue[0] || area.max !== rangeSliderValue[1]) childrenSetArea(rangeSliderValue[0], rangeSliderValue[1])
	}

	//Изменение URL строки
	const navigate = useNavigate()

	//Ужас конечно, но так работает
	//Проверяет каждое значение фильтра, если такого нет удаляет, чтобы не было пустых или дефолтных значений
	React.useEffect(() => {
		if (oneFloor !== 0 && oneFloor !== "") {
			updateFilterValue({ ...filterValue, oneFloor: oneFloor })
		} else {
			const { oneFloor, ...updatedFilterValue } = filterValue
			updateFilterValue(updatedFilterValue)
		}
	}, [oneFloor])

	React.useEffect(() => {
		if (twoFloors !== 0) {
			updateFilterValue({ ...filterValue, twoFloors: twoFloors })
		} else {
			const { twoFloors, ...updatedFilterValue } = filterValue
			updateFilterValue(updatedFilterValue)
		}
	}, [twoFloors])

	React.useEffect(() => {
		if (houseType.length > 0) {
			updateFilterValue({ ...filterValue, houseType: houseType })
		} else {
			const { houseType, ...updatedFilterValue } = filterValue
			updateFilterValue(updatedFilterValue)
		}
	}, [houseType])

	React.useEffect(() => {
		if (category.length > 0) {
			updateFilterValue({ ...filterValue, category: category })
		} else {
			const { category, ...updatedFilterValue } = filterValue
			updateFilterValue(updatedFilterValue)
		}
	}, [category])

	React.useEffect(() => {
		if (materials.length > 0) {
			updateFilterValue({ ...filterValue, materials: materials })
		} else {
			const { materials, ...updatedFilterValue } = filterValue
			updateFilterValue(updatedFilterValue)
		}
	}, [materials])

	React.useEffect(() => {
		if (area.min !== 25 || area.max !== 570) {
			updateFilterValue({ ...filterValue, minArea: area.min, maxArea: area.max })
		} else {
			const { minArea, maxArea, ...updatedFilterValue } = filterValue
			updateFilterValue(updatedFilterValue)
		}

		setRangeSliderValue([area.min, area.max])
	}, [area])

	//Отправка данных на сервер, с блокированием фильтра на 600мс
	const handleFilterSubmit = async (e) => {
		//Блокируем фильтр
		onFilterLoad(true)

		//Создаем строку для запроса и отправки на сервер
		const query = new URLSearchParams(filterValue)
		const queryPost = `/projects?${query}`

		//Меняем строку поиска клиента
		navigate(queryPost)

		//Отправляем данные и убираем блок фильтра
		const response = await axios.post(queryPost).then(
			setTimeout(() => {
				onFilterLoad(false)
			}, 600)
		)

		//Обновляем данные фильтра
		onFilterData(response?.data)
	}

	React.useEffect(() => {
		if (Object.keys(filterValue).length > 0) {
			handleFilterSubmit()
		}
	}, [])

	//При изменении фильтра обновить данные
	React.useEffect(() => {
		handleFilterSubmit()
	}, [filterValue])

	const clearFilter = () => {
		childrenSetArea(25, 570)
		setRangeSliderValue([area.min, area.max])
		childrenSetMaterials([])
		childrenSetCategory([])
		childrenSetHouseType([])
		childrenSetOneFloor(0)
		childrenSetTwoFloors(0)
		updateFilterValue({})
		handleFilterSubmit()
	}

	return (
		<form style={{ padding: "30px" }}>
			<FormGroup>
				<div className="filter-form__wrapper">
					<div className="filter-form__inputs">
						<h2 className="filter-form__header mt-0">Категория дома</h2>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (category.includes("Типовые проекты")) {
										childrenSetCategory(category.filter((el) => el !== "Типовые проекты"))
									} else {
										childrenSetCategory([...category, "Типовые проекты"])
									}
								}}
							>
								<span>
									{category.includes("Типовые проекты") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Типовые проекты</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (houseType.includes("Деревянный дом")) {
										childrenSetHouseType(houseType.filter((el) => el !== "Деревянный дом"))
									} else {
										childrenSetHouseType([...houseType, "Деревянный дом"])
									}
								}}
							>
								<span>
									{houseType.includes("Деревянный дом") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Деревянные дома</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (houseType.includes("Каменный дом")) {
										childrenSetHouseType(houseType.filter((el) => el !== "Каменный дом"))
									} else {
										childrenSetHouseType([...houseType, "Каменный дом"])
									}
								}}
							>
								<span>
									{houseType.includes("Каменный дом") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Каменные дома</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (houseType.includes("Комбинированный дом")) {
										childrenSetHouseType(houseType.filter((el) => el !== "Комбинированный дом"))
									} else {
										childrenSetHouseType([...houseType, "Комбинированный дом"])
									}
								}}
							>
								<span>
									{houseType.includes("Комбинированный дом") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Комбинированные дома</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (houseType.includes("Баня")) {
										childrenSetHouseType(houseType.filter((el) => el !== "Баня"))
									} else {
										childrenSetHouseType([...houseType, "Баня"])
									}
								}}
							>
								<span>
									{houseType.includes("Баня") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Бани</span>
							</div>
						</div>
					</div>

					<div className="filter-form__inputs">
						<h2 className="filter-form__header">Этажность</h2>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (oneFloor === 1) {
										childrenSetOneFloor(0)
									} else {
										childrenSetOneFloor(1)
									}
								}}
							>
								<span>
									{oneFloor === 1 ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Один этаж</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (twoFloors === 2) {
										childrenSetTwoFloors(0)
									} else {
										childrenSetTwoFloors(2)
									}
								}}
							>
								<span>
									{twoFloors === 2 ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Два этажа</span>
							</div>
						</div>
					</div>

					<div className="filter-form__inputs">
						<h2 className="filter-form__header">Технология</h2>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (materials.includes("Каркас")) {
										childrenSetMaterials(materials.filter((el) => el !== "Каркас"))
									} else {
										childrenSetMaterials([...materials, "Каркас"])
									}
								}}
							>
								<span>
									{materials.includes("Каркас") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Каркас</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (materials.includes("Клееный брус")) {
										childrenSetMaterials(materials.filter((el) => el !== "Клееный брус"))
									} else {
										childrenSetMaterials([...materials, "Клееный брус"])
									}
								}}
							>
								<span>
									{materials.includes("Клееный брус") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Клееный брус</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (materials.includes("Бревно")) {
										childrenSetMaterials(materials.filter((el) => el !== "Бревно"))
									} else {
										childrenSetMaterials([...materials, "Бревно"])
									}
								}}
							>
								<span>
									{materials.includes("Бревно") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Бревно</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (materials.includes("Кирпич")) {
										childrenSetMaterials(materials.filter((el) => el !== "Кирпич"))
									} else {
										childrenSetMaterials([...materials, "Кирпич"])
									}
								}}
							>
								<span>
									{materials.includes("Кирпич") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Кирпич</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (materials.includes("Керамоблок")) {
										childrenSetMaterials(materials.filter((el) => el !== "Керамоблок"))
									} else {
										childrenSetMaterials([...materials, "Керамоблок"])
									}
								}}
							>
								<span>
									{materials.includes("Керамоблок") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Керамоблок</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (materials.includes("Газоблок")) {
										childrenSetMaterials(materials.filter((el) => el !== "Газоблок"))
									} else {
										childrenSetMaterials([...materials, "Газоблок"])
									}
								}}
							>
								<span>
									{materials.includes("Газоблок") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Газоблок</span>
							</div>
						</div>

						<br />

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (materials.includes("Фахверк")) {
										childrenSetMaterials(materials.filter((el) => el !== "Фахверк"))
									} else {
										childrenSetMaterials([...materials, "Фахверк"])
									}
								}}
							>
								<span>
									{materials.includes("Фахверк") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Фахверк</span>
							</div>
						</div>

						<div>
							<div
								disabled={isLoading}
								className={isLoading ? "pointer-events-none text-black" : "pointer-events-auto text-white"}
								onClick={() => {
									if (materials.includes("Барнхаус")) {
										childrenSetMaterials(materials.filter((el) => el !== "Барнхаус"))
									} else {
										childrenSetMaterials([...materials, "Барнхаус"])
									}
								}}
							>
								<span>
									{materials.includes("Барнхаус") ? (
										<ImCheckboxChecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									) : (
										<ImCheckboxUnchecked className={`${isLoading ? "fill-black" : "fill-colorMain"} transition-all duration-300 ease-in`} />
									)}
								</span>
								<span>Барнхаус</span>
							</div>
						</div>
					</div>

					<div className="filter-form__inputs">
						<h2 className="filter-form__header">
							Площадь, м<sup>2</sup>
						</h2>
						<div className="w-full">
							<Slider
								disabled={isLoading}
								style={{
									color: theme.palette.primary.main,
									marginRight: ".75rem",
									marginTop: "1.25rem",
								}}
								value={rangeSliderValue}
								min={5}
								max={590}
								step={5}
								onChange={rangeSliderChange}
								onChangeCommitted={handleRangeCommited}
								valueLabelDisplay="auto"
								disableSwap
							/>
						</div>
						<div className="flex justify-between w-full text-white">
							<div>
								{rangeSliderValue[0]} м <sup>2</sup>
							</div>
							<div>
								{rangeSliderValue[1]} м <sup>2</sup>
							</div>
						</div>
					</div>

					<div className="filter-form__clear">
						<Link onClick={clearFilter} className="clear-btn">
							<IoCloseCircleOutline className="clear-icon" /> <span>Очистить фильтр</span>
						</Link>
					</div>
				</div>
			</FormGroup>
		</form>
	)
}

export default Filter
