import React from "react"
import { Link } from "react-router-dom"
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps"

import { ComForm } from "../../components"

import "./ComContacts.scss"

export const ComContacts = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const comAdress = "Москва, Метро ВДНХ, Ярославская улица, д. 8, корп. 6"
	return (
		<div className="comContacts">
			<h2 className="comContacts__title">Контакты</h2>

			<div className="comContacts__general">
				<div className="comContacts__general-content">
					<h3>Телефон</h3>
					<p>
						<Link to="tel:+74950050145">+7(495) 005-01-45</Link>
					</p>
				</div>

				<div className="comContacts__general-content">
					<h3>Почта</h3>
					<p>
						<Link to="mailto:info@artemadera.ru">info@artemadera.ru</Link>
					</p>
				</div>

				<div className="comContacts__general-content">
					<h3>Адрес</h3>
					<p>
						<Link to={`https://yandex.ru/maps/?text=${comAdress}`}>{comAdress}, Офис 220</Link>
					</p>
				</div>
			</div>

			<YMaps>
				<div className="comContacts__map">
					<Map defaultState={{ center: [55.816551, 37.649328], zoom: 17 }} className="w-full h-full">
						<Placemark geometry={[55.816551, 37.649328]} />
					</Map>
				</div>
			</YMaps>

			<div className="comContacts__info">
				<div className="comContacts__requis">
					<h3>Реквизиты</h3>
					<div className="comContacts__requis-content">
						<p>
							<b>Полное наименование</b>
						</p>
						<p>Общество с ограниченной ответственностью «АртеМадера»</p>
					</div>
					<div className="comContacts__requis-content">
						<p>
							<b>Генеральный директор</b>
						</p>
						<p>Бибик Виталий Александрович</p>
					</div>
					<div className="comContacts__requis-content">
						<p>
							<b>ОГРН</b>
						</p>
						<p>1227700087471</p>
					</div>
					<div className="comContacts__requis-content">
						<p>
							<b>ИНН/КПП</b>
						</p>
						<p> 9717111247/771701001</p>
					</div>
					<div className="comContacts__requis-content">
						<p>
							<b>Юридический/фактический адрес:</b>
						</p>
						<p>129164, г. Москва, ул. Ярославская, д.8, к.6, пом. 220</p>
					</div>
					<div className="comContacts__requis-content">
						<p>
							<b>Расчетный счет №</b>
						</p>
						<p>40702810501100027351</p>
					</div>
					<div className="comContacts__requis-content">
						<p>
							<b>Наименование банка</b>
						</p>
						<p>АО "АЛЬФА-БАНК"</p>
					</div>
					<div className="comContacts__requis-content">
						<p>
							<b>БИК Банка</b>
						</p>
						<p>044525593</p>
					</div>
				</div>

				<div className="comContacts__form">
					<h3>Обратная связь</h3>

					<ComForm />
				</div>
			</div>
		</div>
	)
}

export default ComContacts
