import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./redux/store";
import App from "./App.js"
import "./index.scss"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <>
    <BrowserRouter>
        <Provider store = { store }>
          <App />
        </Provider>
      </BrowserRouter>
  </>
)
