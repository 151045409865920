import React from "react"
import { MyContext } from "../../Context/Context"

import "./CurrMaterial.scss"
import brevno from "../../img/brevno.png"
import brevnoWebp from "../../img/brevno.webp"

import brick from "../../img/brick.png"
import brickWebp from "../../img/brick.webp"

import gasoblock from "../../img/gasoblock.png"
import gasoblockWebp from "../../img/gasoblock.webp"

import karkas from "../../img/karkas.png"
import karkasWebp from "../../img/karkas.webp"

import keramoblock from "../../img/keramoblok.jpg"
import keramoblockWebp from "../../img/keramoblok.webp"

import klbrus from "../../img/klbrus.png"
import klbrusWebp from "../../img/klbrus.webp"

export const CurrMaterial = ({ material }) => {
	const { useWebP } = React.useContext(MyContext)
	const [blockText, setBlockText] = React.useState({})
	const [matValue, setMatValue] = React.useState(true)

	React.useEffect(() => {
		setMatValue(material.length > 0 ? material[0] : material)
	}, [])

	React.useEffect(() => {
		switch (matValue) {
			case "Клееный брус": {
				setBlockText({
					header: `Почему именно ${matValue}`,
					bigText1:
						"Клееный брус - это уникальный строительный материал, не требующий постоянного ухода за счет высокой точности обработки и заблаговременной пропитки защитными средствами, абсолютно безопасными для здоровья человека.",
					bigText2:
						"Благодаря высокоточному профилированию дома из клееного бруса более устойчивы. Специальная технология обработки снижает внутреннее напряжение древесины, защищая от деформации и появлению трещин.",
					image: useWebP ? klbrusWebp : klbrus,
					title1: "Отделка сразу",
					text1: "Не нужно ждать усадки полгода",
					title2: "Высокая прочность",
					text2: "За счёт технологичной склейки",
					title3: "Процент усадки",
					text3: "1-2% от исходной высоты дома",
					title4: "Минимум влаги",
					text4: "10-12% (почти не усыхает)",
				})
				break
			}

			case "Газоблок": {
				setBlockText({
					header: `Почему именно Газобетон`,
					bigText1:
						"Газобетон (ячеистый бетон) сегодня считается одним из самых популярных строительных материалов, которые используют на рынке загородного жилья.",
					bigText2:
						"Газобетон полностью отвечает ключевым характеристикам современного загородного строительства: энергоэффективность, безопасность, экологичность. Именно поэтому мы выбрали этот материал для загородного проектирования.",
					image: useWebP ? gasoblockWebp : gasoblock,
					title1: "Изоляционные свойства:",
					text1: "Имеют высокую степень теплоизоляции и звукоизоляции.",
					title2: "Прочность и долговечность:",
					text2: "Обладают высокой прочностью и устойчивостью к различным нагрузкам.",
					title3: "Экологическая дружелюбность:",
					text3: "Являются экологически чистым материалом, так как изготавливаются из натуральных компонентов, таких как цемент, песок и вода.",
					title4: "Удобство и скорость строительства:",
					text4: "Газоблоки легкие и удобны в обработке.",
				})
				break
			}

			case "Бревно": {
				setBlockText({
					header: `Почему именно ${matValue}`,
					bigText1:
						"Бревно является одним из самых старых и традиционных строительных материалов, которые до сих пор широко используются при возведении деревянных домов. Бревно - это цельный круглый ствол дерева, обработанный и выровненный для строительных целей.",
					bigText2:
						"Бревно обладает естественной красотой и уникальным эстетическим обаянием, которые придают дому теплую и уютную атмосферу. Каждое бревно имеет свою особую текстуру, цвет и узор, что делает каждый деревянный дом уникальным произведением искусства. Бревно воплощает в себе естественность и натуральность, что привлекает многих людей к этому материалу.",
					image: useWebP ? brevnoWebp : brevno,
					title1: "Естественная теплоизоляция:",
					text1: "Естественная структура древесины позволяет удерживать тепло внутри дома.",
					title2: "Экологическая дружелюбность:",
					text2: "В процессе его производства не требуется использование вредных химических веществ.",
					title3: "Естественная  долговечность:",
					text3: "Бревно обладает высокой прочностью и устойчивостью к внешним воздействиям.",
					title4: "Уникальность и индивидуальность:",
					text4: "Каждое бревно имеет свою уникальную текстуру, форму и цвет, что придает дому особый характер и шарм.",
				})
				break
			}

			case "Кирпич": {
				setBlockText({
					header: `Почему именно ${matValue}`,
					bigText1:
						"Кирпич - это один из самых распространенных и популярных строительных материалов, который широко используется для возведения зданий. Он представляет собой прямоугольный блок из обожженной глины или иного вяжущего материала. Вот описание кирпича и четыре его преимущества:",
					bigText2:
						"Кирпич обладает прочной и прочносжимаемой структурой, что делает его идеальным материалом для строительства домов. Он имеет однородную и ровную поверхность, которая придает зданию эстетическую привлекательность. Кирпич может быть разных размеров, цветов и текстур, что позволяет создавать различные архитектурные стили и дизайны.",
					image: useWebP ? brickWebp : brick,
					title1: "Прочность и долговечность:",
					text1: "Кирпич является очень прочным материалом, способным выдерживать большие нагрузки и воздействие времени.",
					title2: "Огнестойкость:",
					text2: "Обладает высокой огнестойкостью, что делает его безопасным материалом для строительства.",
					title3: "Энергоэффективность:",
					text3: "Стены обладают хорошими теплоизоляционными свойствами, что способствует сохранению тепла внутри дома.",
					title4: "Звукоизоляция:",
					text4: "Кирпичные стены обладают хорошей звукоизоляцией, что позволяет снизить проникновение шума извне.",
				})
				break
			}

			case "Керамоблок": {
				setBlockText({
					header: `Почему именно ${matValue}`,
					bigText1:
						"Керамоблок - это строительный материал, изготовленный из обожженной глины или керамической смеси. Он представляет собой прямоугольный блок с отверстиями внутри. Керамоблоки широко используются в строительстве как основной материал для возведения стен. Вот описание керамоблока и четыре его преимущества:",
					bigText2:
						"Керамоблоки обладают гладкой и ровной поверхностью, которая придает зданию эстетическую привлекательность. Они имеют однородный цвет и текстуру, что позволяет создавать чистый и аккуратный внешний вид. Керамоблоки могут быть разных размеров и форм, что предоставляет гибкость в выборе дизайна и архитектурных решений.",
					image: useWebP ? keramoblockWebp : keramoblock,
					title1: "Прочность и долговечность:",
					text1: "Керамоблоки обладают высокой прочностью и стойкостью к внешним воздействиям.",
					title2: "Тепло- и звукоизоляция:",
					text2: "Керамоблоки обладают хорошими теплоизоляционными свойствами.",
					title3: "Экологическая дружелюбность:",
					text3: "Керамоблоки изготавливаются из натуральных и экологически чистых материалов, таких как глина или керамика.",
					title4: "Огнестойкость и пожарная безопасность:",
					text4: "Керамоблоки обладают высокой огнестойкостью, что делает их надежной защитой от пожара.",
				})
				break
			}

			case "Каркас": {
				setBlockText({
					header: `Почему именно ${matValue}`,
					bigText1:
						"Деревянный каркас - это тип строительной конструкции, состоящей из деревянных элементов, которые образуют скелет дома. Деревянные балки и столбы соединяются между собой, создавая прочную основу для стен, перекрытий и крыши.",
					bigText2:
						"Каркас является классическим и популярным методом строительства. Он предоставляет прочную и надежную основу для дома, сохраняя природную красоту и теплоту дерева. Деревянные элементы каркаса обычно изготавливаются из сухой и обработанной древесины, чтобы обеспечить долговечность и стабильность конструкции.",
					image: useWebP ? karkasWebp : karkas,
					title1: "Экологическая дружелюбность:",
					text1: "Дерево является природным и экологически чистым материалом.",
					title2: "Тепло- и звукоизоляция:",
					text2: "Дерево обладает естественной теплоизоляцией, что помогает сохранять комфортную температуру внутри дома.",
					title3: "Легкость и гибкость строительства:",
					text3: "Деревянный каркас является легким и относительно простым в строительстве материалом.",
					title4: "Энергоэффективность:",
					text4: "Деревянный каркас обладает хорошей теплоизоляцией и способностью сохранять тепло внутри помещений.",
				})
				break
			}

			default: {
				setBlockText({})
				break
			}
		}
	}, [matValue])

	return (
		<div className="brusMat">
			<div className="brusMat__wrapper">
				<div className="brusMat__why">
					<div className="brusMat__text">
						<h1 className="brusMat__header">{blockText?.header}</h1>
						<div className="brusMat__text-1">{blockText?.bigText1}</div>
						<div className="brusMat__text-1">{blockText?.bigText2}</div>
					</div>

					<div className="brusMat__img">
						<img src={blockText?.image} alt="" className="brus-img" />
					</div>
				</div>

				<div className="brusMat__why-blocks">
					<div className="block">
						<span>{blockText?.title1}</span>
						<p>{blockText?.text1}</p>
					</div>
					<div className="block">
						<span>{blockText?.title2}</span>
						<p>{blockText?.text2}</p>
					</div>
					<div className="block">
						<span>{blockText?.title3}</span>
						<p>{blockText?.text3}</p>
					</div>
					<div className="block">
						<span>{blockText?.title4}</span>
						<p>{blockText?.text4}</p>
					</div>
				</div>
			</div>

			{material[1] === "Керамоблок" ? (
				<div className="brusMat__wrapper">
					<div className="brusMat__why">
						<div className="brusMat__text">
							<h1 className="brusMat__header">Почему именно Керамоблок</h1>
							<div className="brusMat__text-1">
								Керамоблок - это строительный материал, изготовленный из обожженной глины или керамической смеси. Он представляет собой прямоугольный
								блок с отверстиями внутри. Керамоблоки широко используются в строительстве как основной материал для возведения стен. Вот описание
								керамоблока и четыре его преимущества:
							</div>
							<div className="brusMat__text-1">
								Керамоблоки обладают гладкой и ровной поверхностью, которая придает зданию эстетическую привлекательность. Они имеют однородный цвет и
								текстуру, что позволяет создавать чистый и аккуратный внешний вид. Керамоблоки могут быть разных размеров и форм, что предоставляет
								гибкость в выборе дизайна и архитектурных решений.
							</div>
						</div>

						<div className="brusMat__img">
							<img src={useWebP ? keramoblockWebp : keramoblock} alt="" className="brus-img" />
						</div>
					</div>

					<div className="brusMat__why-blocks">
						<div className="block">
							<span>Прочность и долговечность:</span>
							<p>Керамоблоки обладают высокой прочностью и стойкостью к внешним воздействиям.</p>
						</div>
						<div className="block">
							<span>Тепло- и звукоизоляция:</span>
							<p>Керамоблоки обладают хорошими теплоизоляционными свойствами.</p>
						</div>
						<div className="block">
							<span>Экологическая дружелюбность:</span>
							<p>Керамоблоки изготавливаются из натуральных и экологически чистых материалов, таких как глина или керамика.</p>
						</div>
						<div className="block">
							<span>Огнестойкость и пожарная безопасность:</span>
							<p>Керамоблоки обладают высокой огнестойкостью, что делает их надежной защитой от пожара.</p>
						</div>
					</div>
				</div>
			) : material[1] === "Кирпич" ? (
				<div className="brusMat__wrapper">
					<div className="brusMat__why">
						<div className="brusMat__text">
							<h1 className="brusMat__header">Почему именно Кирпич</h1>
							<div className="brusMat__text-1">
								Кирпич - это один из самых распространенных и популярных строительных материалов, который широко используется для возведения зданий.
								Он представляет собой прямоугольный блок из обожженной глины или иного вяжущего материала. Вот описание кирпича и четыре его
								преимущества:
							</div>
							<div className="brusMat__text-1">
								Кирпич обладает прочной и прочносжимаемой структурой, что делает его идеальным материалом для строительства домов. Он имеет однородную
								и ровную поверхность, которая придает зданию эстетическую привлекательность. Кирпич может быть разных размеров, цветов и текстур, что
								позволяет создавать различные архитектурные стили и дизайны.
							</div>
						</div>

						<div className="brusMat__img">
							<img src={useWebP ? brickWebp : brick} alt="" className="brus-img" />
						</div>
					</div>

					<div className="brusMat__why-blocks">
						<div className="block">
							<span>Прочность и долговечность:</span>
							<p>Кирпич является очень прочным материалом, способным выдерживать большие нагрузки и воздействие времени.</p>
						</div>
						<div className="block">
							<span>Огнестойкость:</span>
							<p>Обладает высокой огнестойкостью, что делает его безопасным материалом для строительства.</p>
						</div>
						<div className="block">
							<span>Энергоэффективность:</span>
							<p>Стены обладают хорошими теплоизоляционными свойствами, что способствует сохранению тепла внутри дома.</p>
						</div>
						<div className="block">
							<span>Звукоизоляция:</span>
							<p>Кирпичные стены обладают хорошей звукоизоляцией, что позволяет снизить проникновение шума извне.</p>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	)
}

export default CurrMaterial
