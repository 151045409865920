import { Route, Routes } from "react-router-dom"
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import { MyContextProvider } from "./Context/Context"

import { Home, Projects, FullProject, Blog, Services, Designing, Gallery, FullGallery, ComContacts, Politika, Obrabotka } from "./pages"
import { DomaBezUma, EkoDoma, DomaIzDereva, DomaFahverk } from "./pages/Blogs"
import { WorkingDraft, SketchDesigning } from "./pages/Designings"

function App() {
	return (
		<MyContextProvider>
			<Header />
			<div className="h-[--header-height]"></div>
			<Routes>
				<Route path="/" element={<Home />} />

				<Route path="/projects" element={<Projects />} />
				<Route path="/projects/:id" element={<FullProject />} />

				<Route path="/services" element={<Services />} />
				<Route path="/services/designing" element={<Designing />} />
				<Route path="/services/designing/sketch-designing" element={<SketchDesigning />} />
				<Route path="/services/designing/working-draft" element={<WorkingDraft />} />

				<Route path="/gallery" element={<Gallery />} />
				<Route path="/gallery/:id" element={<FullGallery />} />

				<Route path="/blog" element={<Blog />} />
				<Route path="/blog/doma-ot-kotoryh-ves-mir-bez-uma" element={<DomaBezUma />} />
				<Route path="/blog/ekologichnost-derevyannyix-domov" element={<EkoDoma />} />
				<Route path="/blog/doma-iz-dereva" element={<DomaIzDereva />} />
				<Route path="/blog/fahverkovii-dom" element={<DomaFahverk />} />

				<Route path="/contacts" element={<ComContacts />} />

				<Route path="/politika-konfidentsialnosti/" element={<Politika />} />
				<Route path="/obrabotka-personalnykh-dannykh/" element={<Obrabotka />} />
			</Routes>
			<Footer />
		</MyContextProvider>
	)
}

export default App
