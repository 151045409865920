import React from "react"

import "./MobileMenu.scss"

import { HiExternalLink, HiOutlineDeviceMobile, HiOutlineMail, HiOutlinePhoneIncoming, HiChevronDown } from "react-icons/hi"

export const MobileMenu = ({ woodenType, typicalCategory, bathType, combinedType, stoneType, handleOpenForm, toggleMenu, clearFilter }) => {
	const [activeAccordion, setActiveAccordion] = React.useState(null)

	const handleAccordionClick = (index) => {
		setActiveAccordion(index === activeAccordion ? null : index)
	}

	return (
		<div className={`mobileMenu ${toggleMenu === true ? "active" : ""}`}>
			<div className="mobileMenu__wrapper">
				<div className="mobileMenu__acc">
					<div onClick={() => handleAccordionClick(1)} className={`mobileMenu__acc-toggler ${activeAccordion === 1 ? "bg-gray-300" : ""}`}>
						<h2>Проекты</h2>{" "}
						<span>
							<HiChevronDown
								size={20}
								className={`text-colorDarkGrey transition-all duration-300 ease-out ${activeAccordion === 1 ? "-rotate-180" : ""}`}
							/>
						</span>
					</div>
					<div className={`mobileMenu__acc-content ${activeAccordion === 1 ? "active" : "--inactive"}`}>
						<a href="/projects" onClick={clearFilter}>
							Смотреть все
						</a>

						<a href="/projects" onClick={woodenType}>
							Деревянные дома
						</a>
						<a href="/projects" onClick={stoneType}>
							Каменные дома
						</a>
						<a href="/projects" onClick={combinedType}>
							Комбинированные дома
						</a>
						<a href="/projects" onClick={bathType}>
							Бани
						</a>
						<a href="/projects" onClick={typicalCategory}>
							Типовые проекты
						</a>
					</div>
					<div onClick={() => handleAccordionClick(2)} className={`mobileMenu__acc-toggler ${activeAccordion === 2 ? "bg-gray-300" : ""} `}>
						<h2>Услуги</h2>{" "}
						<span>
							<HiChevronDown
								size={20}
								className={`text-colorDarkGrey transition-all duration-300 ease-out ${activeAccordion === 2 ? "-rotate-180" : ""}`}
							/>
						</span>
					</div>
					<div className={`mobileMenu__acc-content ${activeAccordion === 2 ? "active" : "--inactive"}`}>
						<a href="/services">Смотреть все</a>
						{/* <div className="submenu__item">
								<a href="/services/designing">Проектирование домов</a>
							</div> */}

						<a href="https://artemadera.ru/ingeneriya/" target="_blank">
							Инженерные коммуникации
						</a>

						<a href="https://artemadera.ru/" target="_blank">
							Отделка деревянных домов
						</a>

						<a href="https://artemadera.ru/obsada/" target="_blank">
							Обсада
						</a>

						<a href="https://artemadera.ru/okna/" target="_blank">
							Окна
						</a>

						<a href="https://artemadera.ru/dveri/" target="_blank">
							Двери
						</a>

						<a href="https://artedecora.ru/" target="_blank">
							Декоративные панели
						</a>
					</div>
					{/* <h2 onClick={() => handleAccordionClick(3)}>Галерея</h2>
					<div className={`mobileMenu__acc-content ${activeAccordion === 3 ? "active" : ""}`}>
						<a href="/gallery">Смотреть всё</a>

					</div> */}
					<a className="mobileMenu__acc-content_alone" href="/gallery">
						<span>Галерея</span>
					</a>
					<a className="mobileMenu__acc-content_alone" href="/blog">
						<span>Блог</span>
					</a>
					<a className="mobileMenu__acc-content_alone" href="#">
						<span>О нас</span>
					</a>
					<a className="mobileMenu__acc-content_alone" href="/contacts">
						<span>Контакты</span>
					</a>
					<div className="nav-action">
						<ul className="mobileMenu__action">
							<li className="nav-action-link mobile-action">
								<HiOutlineDeviceMobile className="text-colorMain text-xl" />
								<a href="tel:+74950050145">+7(495) 005-01-45</a>
							</li>
							<li className="nav-action-link mobile-action">
								<HiOutlineMail className="text-colorMain text-xl" />
								<a href="mailto:info@artemadera.ru">info@artemadera.ru</a>
							</li>
							<li onClick={handleOpenForm}>
								<HiOutlinePhoneIncoming className="text-colorMain text-xl" />
								<span className="get-call-form mobile-action">заказать звонок</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MobileMenu
