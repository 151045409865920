import React from "react"
import "./blogs.scss"

import blogImg1 from "../../img/article1.webp"

export const DomaBezUma = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className="blogs container mx-auto px-8">
			<div className="blogs__wrapper">
				<h1 className="blogs__title">ДОМА, ОТ КОТОРЫХ ВЕСЬ МИР БЕЗ УМА</h1>

				<div className="blogs__image-1">
					<img src={blogImg1} alt="ДОМА, ОТ КОТОРЫХ ВЕСЬ МИР БЕЗ УМА" />
				</div>

				<h2 className="blogs__sub-title">ДОМА, ОТ КОТОРЫХ ВЕСЬ МИР БЕЗ УМА</h2>

				<p className="blogs__text">
					Технология, подарившая миллионам семей уютный дом, уже известна более ста лет. Маленький финский домик - прототип современного дома из
					клееного бруса. Впервые такие стали изготавливать в Финляндии в 1920-х годах.
				</p>

				<p className="blogs__text">
					Со временем в финском домостроительстве также начали использовать бетон и кирпич. Дерево же осталось классическим материалом, которое
					используется и поныне. Финский дом хранит в себе целую культуру народа. Он символ тепла и уюта, его главные достоинства геометрическая
					простота и функциональность. Финская технология усовершенствовалась, спустя многие годы её продолжают использовать в строительстве домов
					охотнее и чаще, доверяя опыту прошлых поколений.
				</p>

				<p className="blogs__text">
					Финляндия всегда была образцом для подражания в сфере загородного домостроения. Клееный брус был придуман финскими инженерами для того,
					чтобы улучшить теплоизоляцию и прочность домов из обычной древесины.
				</p>

				<p className="blogs__text">
					Финляндия известна многим своим климатом - суровой зимой и очень теплым летом. В холодную пору года практически на всей территории страны
					температура воздуха достигает до −20 °C и более. Северная часть государства находится под снегом 200-225 дней в году. И в этой ярко
					выраженной северной стране предпочитают практичные и долговечные дома из дерева.
				</p>

				<h2 className="blogs__sub-title">ВСЕ САМОЕ ЛУЧШЕЕ ДАЛА ПРИРОДА</h2>

				<p className="blogs__text">
					Деревянный дом в последние годы становится всё более популярным приобретением, нежели лет двадцать назад. Такую востребованность несложно
					объяснить, она обусловлена преимуществами используемой технологии. Но самая главная особенность таких построек - это натуральность и
					экологичность материала. Без данной технологии не обходится строительство домов в Нидерландах, климат которых отличается повышенной
					влажностью.
				</p>

				<p className="blogs__text">
					Находясь подолгу в деревянном доме, начинаешь испытывать тесную взаимосвязь с природой. Выстраиваешь с ней гармоничные отношения. Дерево
					положительно влияет на качество сна. Эфирные масла в составе древесины успокаивают нервную систему человека. Работа сердечно-сосудистой
					системы становится более стабильной. В деревянном доме редко кто сможет находиться в тяжелом стрессовом состоянии. Этот природой данный
					материал имеет удивительное свойство - расслаблять и снимать напряжение.
				</p>

				<h2 className="blogs__sub-title">ЛЮДЕЙ МНОГО - ВЫБОР ОДИН</h2>

				<p className="blogs__text">
					Качество жизни в деревянном домике устраивает миллионы людей по всему миру. Ведь в производстве используется качественная, органическая и
					здоровая древесина.
				</p>

				<p className="blogs__text">
					Дерево имеет хорошую терморегуляцию. В знойную жару в доме всегда будет прохлада. В зимнее время такой дом станет самым теплым.
				</p>

				<p className="blogs__text">
					Простота в дизайне и быстрота постройки домов из клееного бруса, обеспечили лидерство технологии по всему миру. На возведение дома уйдет от
					трех до шести месяцев. Используемое дерево не имеет свойства растрескиваться. А прочность конструкции способна выдержать перепады температур
					и климатические аномалии в течение ста лет. И эта цифра не является верхним пределом срока использования деревянного жилья.
				</p>

				<p className="blogs__text">
					Строительство дома из бруса имеет экономическую выгоду. Будущему владельцу не потребуется тратить средства на внутреннюю отделку помещений.
					О фактуре и дизайне позаботилась сама природа.
				</p>

				<p className="blogs__text">
					Начинать строительство дома из клееного бруса можно в любое время года. У этого материала минимальный процент усадки - всего 0,5-1%.
					Изготовление клееного бруса состоит из нескольких этапов. В самом начале доски ждет специальная обработка. После этого их тщательно
					просушивают, а затем сухие склеивают воедино. Для сушки используют специальные камеры, поэтому риск влажности древесины минимальный.
				</p>

				<h2 className="blogs__sub-title">СОВРЕМЕННЫЕ ТЕХНОЛОГИИ СДЕЛАЛИ СВОЕ ДЕЛО</h2>

				<p className="blogs__text">
					До того как дерево превратили в строительный материал, оно прошло специальную обработку. Древесину опрыскали растворами - антипиренами,
					полностью безопасными для здоровья человека. Они придают деревянным элементам прочность и долговечность.
				</p>

				<p className="blogs__text">
					Жизнь в деревянном доме - это традиция финских предков, которые сделали эту технологию постройки доступной всему миру.
				</p>
			</div>
		</div>
	)
}

export default DomaBezUma
