import React from "react"
import "./blogs.scss"

export const DomaIzDereva = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="blogs container mx-auto px-8">
			<div className="blogs__wrapper">
				<h1 className="blogs__title">КАКИЕ БЫВАЮТ ДОМА ИЗ ДЕРЕВА?</h1>

				<p className="blogs__text">
					Строительство сооружений из дерева имеет долгую и богатую историю,
					исчисляемую веками и тысячелетиями. Сегодня дом из дерева — совсем не
					тот, что был когда-то. Современные технологии открыли новые
					возможности и перспективы для построек из дерева. Деревянное
					домостроение сегодня снова стало одним из самых выгодных направлений
					по созданию доступного жилья. Актуальные технологии искоренили
					большинство недостатков деревянных домов, сделав их надежными,
					доступными и современными.
				</p>

				<h2 className="blogs__sub-title">
					Мифы про опасность клеенного бруса.
				</h2>

				<h3 className="blogs__header">ДЕРЕВЯННЫЕ ДОМА ИЗ БРУСА</h3>

				<p className="blogs__text">
					Раньше дома часто строили из полноценных бревен — бревенчатого сруба.
					Сейчас такое строительство потеряло свою актуальность, в основном,
					из-за высокой стоимости и очень трудоемкого строительства. На смену
					традиционному дому из сруба пришли дома из клееного бруса. Внешне их
					легко отличить — дом из клееного бруса имеет ровные стены. Цена на
					древесину по-прежнему относительно высока, но такая технология
					удешевляет строительство дома в разы. Более того, цена будет даже
					скомпенсирована за счет того, что вы можете не заниматься
					дополнительной отделкой стен — достаточно вскрыть их лаком или
					покрасить. Дом при этом останется теплым и сохранит все свойства
					деревянных домов. Клееный брус склеивают из пиленых досок, такой
					материал более практичен еще и потому, что на его усадку требуется
					гораздо меньше времени, чем традиционному срубу.
				</p>

				<h3 className="blogs__header">ФАХВЕРКОВЫЕ ДОМА</h3>

				<p className="blogs__text">
					Широко распространенные в Европе средних веков, фахверки прошли
					большой путь и превратились в современные дома с огромным перечнем
					преимуществ. Из старинной технологии строительства, современные
					фахверки унаследовали каркас из дерева, а остальные элементы здания
					значительно модернизировались. Сейчас такие дома отличаются своим
					светлым, просторным дизайном, который возможен благодаря использованию
					современного энергоэффективного остекления. Панорамные окна — далеко
					не единственное преимущество фахверка. Современные фахверковые дома
					укомплектованы целыми комплексами технологичных решений, которые
					позволяют сделать их по-настоящему «умными» и комфортными. При этом,
					строительство не занимает много времени, а стоимость может быть очень
					доступной.
				</p>

				<h3 className="blogs__header">КАРКАСНЫЙ ДОМ ИЗ ДЕРЕВА</h3>

				<p className="blogs__text">
					Еще одна перспективная современная технология строительства домов из
					дерева — каркасное деревянное домостроение. Дома по каркасной
					технологии экономически очень привлекательны, их строительство
					занимает немного времени, а перечень преимуществ впечатляет. При
					строительстве домов по каркасной технологии, сначала возводится сам
					каркас, который позже наполняется современным утеплителем и обшивается
					с двух сторон. Внутри это пароизоляция (термосберегающий слой), а
					снаружи — влагоотталкивающие и ветроустойчивые материалы обшивки.
					Необходима и дополнительная обшивка конструкции, для нее используют
					жесткие листовые материалы (фанеру, гипсокартон, цементно-стружечные
					плиты). Финальный этап — декоративная облицовка, для нее используют
					самые разные материалы, поэтому внешний вид дома может быть любым.
					Несмотря на такое количество процессов и слоев, толщина стен у дома,
					построенного по каркасной технологии, очень незначительная. И именно
					благодаря количеству слоев, деревянный домик остается теплым,
					энергоэффективным и очень комфортным для жизни.
				</p>
			</div>
		</div>
	)
}

export default DomaIzDereva
