import React from "react"
import { useParams } from "react-router-dom"
import axios from "../../axios"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Autoplay, Pagination } from "swiper"

import Lightbox from "yet-another-react-lightbox"
import Captions from "yet-another-react-lightbox/plugins/captions"
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"
import Zoom from "yet-another-react-lightbox/plugins/zoom"
import "yet-another-react-lightbox/plugins/captions.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"
import "yet-another-react-lightbox/styles.css"

import "swiper/scss"
import "swiper/scss/navigation"
import "swiper/scss/pagination"
import "./FullProject.scss"

import { FinishedHouses, SimilarHouses, CurrMaterial, Queries, DefForm, Socials, HouseTabs } from "../../components"

import svgGuarantee from "../../img/guarantee.png"
import svgCustomer from "../../img/customer.png"
import svgSharing from "../../img/sharing.png"
import svgList from "../../img/list.png"
import pngFloor from "../../img/floors.png"
import pngRoom from "../../img/rooms.png"
import pngBath from "../../img/bath.png"

export const FullProject = () => {
	//Project data
	const [data, setData] = React.useState(true)

	//Waiting for project load
	const [isLoading, setLoading] = React.useState(true)

	//Get project by ID
	const { id } = useParams()

	//For swiper lightbox
	const [lightboxIsOpen, setLightboxIsOpen] = React.useState(false)
	const [activeIndex, setActiveIndex] = React.useState(0)

	//Clink on image setting swiper lighbox
	const handleImageClick = (index) => {
		setActiveIndex(index)
		setLightboxIsOpen(true)
	}

	//For plan lightbox
	const [planLightboxIsOpen, setPlanLightboxIsOpen] = React.useState(false)
	const [planActiveIndex, setPlanActiveIndex] = React.useState(0)

	//Clink on image setting plan lighbox
	const handlePlanImageClick = (index) => {
		setPlanActiveIndex(index)
		setPlanLightboxIsOpen(true)
	}

	//Getting project data
	React.useEffect(() => {
		axios
			.get(`/projects/${id}`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => {
				console.warn(err)
				alert("Ошибка при получении проекта")
			})

		window.scrollTo(0, 0)
	}, [])

	//Images array
	const images = data.images

	//Plan images array
	const planPhoto = [data.firstFloorImage, data.secondFloorImage]

	//Deleting undefined photos
	const planImages = planPhoto.filter((image) => image)

	//If loading true then return nothing
	//Need to change (Skeleton)
	if (isLoading) return ""

	return (
		<div className="FullProject mt-12">
			<h1 className="full__title">{data.title}</h1>

			<div className="full__content content">
				<div className="content__swiper">
					<Swiper
						style={{
							"--swiper-navigation-color": "#fbbe00",
							"--swiper-pagination-color": "#fff",
						}}
						modules={[Navigation, Autoplay, Pagination]}
						className="swiper-house"
						slidesPerView={1}
						speed={500}
						loop={true}
						navigation
						pagination={{ clickable: true }}
					>
						{images.map((img, index) => (
							<SwiperSlide key={index} className="house-slide">
								<img
									src={img}
									alt={data.title + " " + index}
									className="slide-img"
									onClick={() => {
										handleImageClick(index)
									}}
								/>
							</SwiperSlide>
						))}
					</Swiper>

					<Lightbox
						open={lightboxIsOpen}
						close={() => setLightboxIsOpen(false)}
						slides={images.map((image) => ({ src: image }))}
						plugins={[Captions, Fullscreen, Thumbnails, Zoom]}
						index={activeIndex}
					/>

					<Lightbox
						open={planLightboxIsOpen}
						close={() => setPlanLightboxIsOpen(false)}
						slides={planImages.map((image) => ({ src: image }))}
						plugins={[Captions, Fullscreen, Thumbnails, Zoom]}
						index={planActiveIndex}
					/>
				</div>

				<div className="content__data data">
					<div className="data__area">
						<div className="area flex flex-col justify-center items-center gap-y-0">
							<span>Площадь</span>
							<span className="normal-case text-xs">(по осям)</span>
						</div>
						<div>
							{data.area} м <sup>2</sup>
						</div>
					</div>

					<p className="data__material">
						<span>Материал стен</span> {data.wallMaterial.length > 0 ? data.wallMaterial.join(" + ") : data.wallMaterial}
					</p>

					<div className="data__counts counts">
						<div className="counts__data-title">
							{data.floorsCount} {data.floorsCount === 1 ? "этаж" : "этажа"}
							<div className="counts__data">
								{data.floorsCount}
								<img src={pngFloor} alt="Floors" />
							</div>
						</div>

						<div className="counts__data-title">
							Комнат
							<div className="counts__data">
								{data.roomsCount}
								<img src={pngRoom} alt="Rooms" />
							</div>
						</div>

						<div className="counts__data-title">
							Санузла
							<div className="counts__data">
								{data.toiletsCount}
								<img src={pngBath} alt="Bath" />
							</div>
						</div>
					</div>

					<div className="data__area price-title">
						<div className="area">Цена</div>
						<div>По запросу</div>
					</div>

					<div className="data__buttons">
						<button className="btn get-price-btn">Получить цену строительства</button>
					</div>
				</div>
			</div>

			<div className="full__tabs tabs">
				<div className="tabs__buttons">
					<HouseTabs
						planImage1={data.firstFloorImage}
						planArea1={data.firstFloorArea}
						planImage2={data.secondFloorImage}
						planArea2={data.secondFloorArea}
						onPlanImageClick={handlePlanImageClick}
						projDesc={data.about}
					/>
				</div>
			</div>

			<div className="full__included">
				<h1 className="inc-title">Включено:</h1>
				<div className="item-wrapper">
					<div className="item">
						<img src={svgGuarantee} alt="Гарантия" className="item__image" />
						<br />
						<strong className="item__bold">50</strong> лет гарантии
					</div>

					<div className="item">
						<img src={svgCustomer} alt="Персональный менеджер" className="item__image" />
						<br />
						Персональный <strong className="item__bold">менеджер</strong>
					</div>

					<div className="item">
						<img src={svgSharing} alt="Сопровождение заказчика" className="item__image" />
						<br />
						<strong className="item__bold">Сопровождение</strong> заказчика
					</div>

					<div className="item">
						<img src={svgList} alt="Технадзор" className="item__image" />
						<br />
						<strong className="item__bold">Технадзор</strong>
					</div>
				</div>
			</div>

			<div className="full__finished">
				<FinishedHouses />
			</div>

			<div className="full__currMaterial">
				<CurrMaterial material={data.wallMaterial} />
			</div>

			<div className="full__similar">
				<SimilarHouses area={data.area} houseType={data.houseType} />
			</div>

			<div className="full__queries">
				<Queries material={data.wallMaterial} />
			</div>

			<div className="full__defForm">
				<DefForm title={"Понравился этот дом?"} />
			</div>

			<div className="container mx-auto full__socials">
				<Socials />
			</div>
		</div>
	)
}
