import React from "react"
import ReactPlayer from "react-player"

import SwiperLarge from "../components/SwiperLarge/SwiperLarge"
import WeBuild from "../components/WeBuild/WeBuild"
import { BlogsSwiper, DefForm, Socials } from "../components"

export const Home = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<>
			<SwiperLarge />
			<WeBuild />
			<BlogsSwiper />

			<div className="lg:container mx-auto px-4 md:px-8 flex justify-center mb-16 h-[300px] md:h-[350px] lg:h-[450px] nik:h-[550px] xl:h-[700px]">
				<ReactPlayer controls={true} width={"100%"} height={"100%"} url="https://www.youtube.com/watch?v=s6boIBwS6pk" />
			</div>

			<DefForm title={"Заявка на строительство вашего идеального дома"} />
			<Socials />
		</>
	)
}
