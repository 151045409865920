import React from "react"
import Skeleton from "@mui/material/Skeleton"
import "./House.scss"

export const HouseSkeleton = () => {
	return (
		<div className="house relative w-full md:w-[47%] h-[300px] sm:h-[400px] xl:h-[500px] bg-gray-300">
			<Skeleton variant="rectangular" className="house__mansarda" width={120} height={30} />
			<div>
				<div className="house__data bg-gray-400 w-4/5 rounded-sm">
					<Skeleton variant="text" style={{ width: "100%" }} height={40} />
				</div>

				<div className="bg-gray-400 absolute w-[100px] right-0 -bottom-5 py-1 px-2 rounded-sm"> 
					<Skeleton variant="text" width="100%" height={30} />
				</div>
			</div>
		</div>
	)
}

export default HouseSkeleton
