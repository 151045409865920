import React from "react"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import { MyContext } from "../../Context/Context"

import { BsChevronRight } from "react-icons/bs"

import "swiper/scss"
import "swiper/scss/navigation"
import "./BlogsSwiper.scss"

import article1 from "../../img/article1.webp"
import article2 from "../../img/article2.jpg"
import article2Webp from "../../img/article2.webp"
import article3 from "../../img/article3.jpeg"
import article3Webp from "../../img/article3.webp"
import article4 from "../../img/article4.jpg"
import article4Webp from "../../img/article4.webp"

export const BlogsSwiper = () => {
	const { useWebP } = React.useContext(MyContext)

	return (
		<div className="blogsSwiper">
			<div className="blogsSwiper__wrapper">
				<h1 className="blogsSwiper__header">Блог</h1>

				<div className="blogsSwiper__shadow-box"></div>
				<div className="blogsSwiper__content">
					<Swiper
						style={{
							"--swiper-navigation-color": "#fff",
						}}
						modules={[Navigation]}
						className="blogsSwiper__swiper"
						slidesPerView={1.5}
						spaceBetween={10}
						speed={500}
						navigation={{
							nextEl: ".blogsSwiper__my-button-next",
						}}
					>
						<SwiperSlide className="blogsSwiper__swiper-slide">
							<Link className="blogsSwiper__swiper-link" to="/blog/doma-ot-kotoryh-ves-mir-bez-uma">
								<div className="blogsSwiper__swiper-slide__image-container">
									<img src={article1} alt="Дома, от которых весь мир без ума" />
								</div>
								<h2 className="blogsSwiper__swiper-title">Дома, от которых весь мир без ума</h2>
							</Link>
						</SwiperSlide>
						<SwiperSlide className="blogsSwiper__swiper-slide">
							<Link className="blogsSwiper__swiper-link" to="/blog/ekologichnost-derevyannyix-domov">
								<div className="blogsSwiper__swiper-slide__image-container">
									<img src={useWebP ? article2Webp : article2} alt="Экологичность домов из клееного бруса: мифы и реальность" />
								</div>
								<h2 className="blogsSwiper__swiper-title">Экологичность домов из клееного бруса: мифы и реальность</h2>
							</Link>
						</SwiperSlide>
						<SwiperSlide className="blogsSwiper__swiper-slide">
							<Link className="blogsSwiper__swiper-link" to="/blog/fahverkovii-dom">
								<div className="blogsSwiper__swiper-slide__image-container">
									<img src={useWebP ? article3Webp : article3} alt="Что такое фархверковый дом?" />
								</div>
								<h2 className="blogsSwiper__swiper-title">Что такое фархверковый дом?</h2>
							</Link>
						</SwiperSlide>
						<SwiperSlide className="blogsSwiper__swiper-slide">
							<Link className="blogsSwiper__swiper-link" to="/blog/doma-iz-dereva">
								<div className="blogsSwiper__swiper-slide__image-container">
									<img src={useWebP ? article4Webp : article4} alt="Какие бывают дома из дерева?" />
								</div>
								<h2 className="blogsSwiper__swiper-title">Какие бывают дома из дерева?</h2>
							</Link>
						</SwiperSlide>
					</Swiper>
				</div>
				<div className="blogsSwiper__my-button-next">
					<BsChevronRight className="text-3xl fill-white" />
				</div>
			</div>
		</div>
	)
}

export default BlogsSwiper
