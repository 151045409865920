import React from "react"
import { Link } from "react-router-dom"

import "./style.scss"

import designImg1 from "../../img/designings1.webp"
import designImg2 from "../../img/designings2-2.webp"
import { BsCheckCircleFill } from "react-icons/bs"

export const WorkingDraft = () => {
	return (
		<div className="designings container py-4 mx-auto">
			<h1 className="designings__title">РАБОЧИЙ ПРОЕКТ</h1>

			<div className="designings__wrapper">
				<h3 className="designings__header designings__header-italic">
					Наше архитектурное бюро предлагает индивидуальные решения по выполнению рабочего проекта, который необходим
					для начала строительных работ, а также для общего видения заказчиком своего будущего деревянного дома.
				</h3>

				<p className="designings__text">
					Рабочий проект подробно описывает архитектурные и конструктивные решения для деревянного дома, подключение
					инженерных сетей. Рабочий проект содержит в себе несколько разделов: архитектурный, конструктивный и
					инженерный (системы электрооборудования, отопления, кондиционирования и вентиляции, водоснабжения и
					канализации).
				</p>

				<div className="designings__image">
					<img src={designImg1} alt="РАБОЧИЙ ПРОЕКТ" loading="lazy" />
				</div>

				<h2 className="designings__sub-title">АРХИТЕКТУРНЫЙ РАЗДЕЛ</h2>

				<p className="designings__text">
					Архитектурный раздел выполняется на основе технического задания и эскизного проекта деревянного дома и
					включает в себя следующую информацию:
				</p>

				<ul className="designings__list">
					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Общие планы всех этажей деревянного дома с размерами.
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Планы этажей дома с условным расположением мебели и оборудования.
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Фасады деревянного дома в цветовых решениях и в осях с отметками высоты.
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Планы-спецификации дверей и окон.
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						План кровли, стен и перекрытий с основными размерами.
					</li>

					<li>
						<span>{<BsCheckCircleFill className="list-icon" />}</span>
						Разрезы фасадов деревянного дома (продольный и поперечный).
					</li>
				</ul>

				<div className="designings__image">
					<img src={designImg2} alt="Архитектурный раздел" loading="lazy" />
				</div>

				<h2 className="designings__sub-title">КОНСТРУКТИВНЫЙ РАЗДЕЛ</h2>

				<p className="designings__text">
					После выполнения архитектурного проектирования, в работу включается инженер конструктор. В процессе выполнения
					конструктивного раздела рабочего проекта производится полный расчет несущей способности фундамента,
					проектирование деревянных конструкций перекрытий, стен и стропильной системы крыши деревянного дома.
				</p>

				<h2 className="designings__sub-title">ИНЖЕНЕРНЫЙ РАЗДЕЛ</h2>

				<p className="designings__text">
					Проект инженерных сетей выполняется после того, когда сформулирована задача по эксплуатации объекта.
					Документация инженерного раздела включает в себя схемы систем электрооборудования, вентиляции и
					кондиционирования, отопления, водоснабжения и канализации, спецификации оборудования, а так же описание и
					схематическое размещение внутри деревянного дома.
				</p>

				<h2 className="designings__sub-title">АРХИТЕКТОРЫ И ПОРТФОЛИО ПРОЕКТОВ</h2>

				<p className="designings__text">
					Наши архитекторы с радостью помогут Вам воплотить в жизнь любые Ваши идеи по проектированию дома и
					обустройству Вашего участка. Специалисты нашего архитектурного бюро обладают большим опытом в проектировании
					деревянных домов, а также реконструкции зданий и сооружений из клееного бруса.
				</p>

				<h2 className="designings__sub-title">УСЛУГИ ПРОЕКТИРОВАНИЯ:</h2>

				<p className="designings__text designings__text-center">
					Эскизный архитектурный проект – стоимость от 6 $ за кв. м.
				</p>

				<p className="designings__text designings__text-center">Рабочий проект – стоимость от 24 $ за кв. м.</p>

				<div className="mt-16">
					<b>
						<p className="designings__text designings__text-center">
							Выберите понравившийся Вам проект деревянного дома на нашем сайте.
						</p>

						<p className="designings__text designings__text-center">
							На его основе мы разработаем индивидуальное эскизное решение для Вас.
						</p>

						<p className="designings__text designings__text-center">
							Если Вы не нашли подходящий проект, свяжитесь с нами — мы предложим Вам альтернативу!
						</p>
					</b>
				</div>

				<div className="designings__bottom-links">
					<Link to="/projects" className="bottom-link more-houses-link" target="_blank">
						Больше проектов
					</Link>

					<Link to="#" className="bottom-link show-gallery-link" target="_blank">
						Смотреть галерею
					</Link>
				</div>
			</div>
		</div>
	)
}

export default WorkingDraft
