import React from "react"
import Article from "../../components/Article/Article"
import { MyContext } from "../../Context/Context"
import "./Blog.scss"

import article1 from "../../img/article1.webp"

import article2 from "../../img/article2.jpg"
import article2Webp from "../../img/article2.webp"

import article3 from "../../img/article3.jpeg"
import article3Webp from "../../img/article3.webp"

import article4 from "../../img/article4.jpg"
import article4Webp from "../../img/article4.webp"

export const Blog = () => {
	const { useWebP } = React.useContext(MyContext)
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="blog">
			<h2 className="blog__title">БЛОГ КОМПАНИИ</h2>
			<h3 className="blog__sub-title">СТАТЬИ И НОВОСТИ</h3>

			<div className="blog__wrapper">
				<Article
					img={article1}
					title="ДОМА, ОТ КОТОРЫХ ВЕСЬ МИР БЕЗ УМА"
					text="Технология, подарившая миллионам семей уютный дом, уже известна более ста лет. 
                    Маленький финский домик - прототип современного дома из клееного бруса. Впервые такие стали изготавливать в Финляндии в 1920-х годах."
					link="doma-ot-kotoryh-ves-mir-bez-uma"
				/>
				<Article
					img={useWebP ? article2Webp : article2}
					title="ЭКОЛОГИЧНОСТЬ ДОМОВ ИЗ КЛЕЕНОГО БРУСА: МИФЫ И РЕАЛЬНОСТЬ"
					text="Безопасен ли клеенный брус? На самом деле это выдумки компаний, строящих не из клееного бруса. 
                    Клеенный брус полностью безопасен и в более развитых странах потребители об этом даже не беспокоятся."
					link="ekologichnost-derevyannyix-domov"
				/>
				<Article
					img={useWebP ? article3Webp : article3}
					title="ЧТО ТАКОЕ ФАХВЕРКОВЫЙ ДОМ?"
					text="Если вы мечтаете о современном, экологичном доме с невероятным дизайном и панорамным остеклением — технология фахверковых домов станет воплощением ваших задумок в реальность.
                    Первое, чем подкупают фахверковые дома — своим дизайном. Но это не все, чем может подкупить фахверк."
					link="fahverkovii-dom"
				/>
				<Article
					img={useWebP ? article4Webp : article4}
					title="КАКИЕ БЫВАЮТ ДОМА ИЗ ДЕРЕВА?"
					text="Строительство сооружений из дерева имеет долгую и богатую историю, исчисляемую веками и тысячелетиями. 
                    Сегодня дом из дерева — совсем не тот, что был когда-то. Современные технологии открыли новые возможности и перспективы для построек из дерева.
                    Деревянное домостроение сегодня снова стало одним из самых выгодных направлений по созданию доступного жилья."
					link="doma-iz-dereva"
				/>
			</div>
		</div>
	)
}

export default Blog
