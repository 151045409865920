import React from "react"
import axios from "../../axios"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"

import "swiper/scss"
import "swiper/scss/navigation"
import "swiper/scss/pagination"

import "./FinishedHouses.scss"

export const FinishedHouses = () => {
	const [data, setData] = React.useState(true)
	const [isLoading, setLoading] = React.useState(true)
	const [slidesPerView, setSlidesPerView] = React.useState(3)

	//Для отображения слайдов при разной ширине
	React.useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth
			if (width > 1025) {
				setSlidesPerView(3)
			} else if (768 < width && width < 1025) {
				setSlidesPerView(2)
			} else {
				setSlidesPerView(1)
			}
		}

		window.addEventListener("resize", handleResize)
		handleResize()

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [])

	React.useEffect(() => {
		axios
			.get(`/finished`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => {
				console.warn(err)
				alert("Ошибка при получении проектов")
			})
	}, [])

	return (
		<div className="finished">
			<h1 className="finished__title">ФОТОГРАФИИ ПОСТРОЕННЫХ ДОМОВ</h1>
			<Link to="/gallery" className="finished__sub-title">
				<p className="finished__sub-title">Смотреть галерею реализованных проектов</p>
			</Link>

			<div className="finished__projects">
				<Swiper
					style={{
						"--swiper-navigation-color": "#000",
						"--swiper-pagination-color": "#fff",
					}}
					modules={[Navigation, Pagination]}
					className="swiper-finished"
					spaceBetween={10}
					slidesPerView={slidesPerView}
					speed={500}
					loop={true}
					navigation={true}
					pagination={{ clickable: true }}
				>
					{!isLoading ? (
						data.map((obj, cnt = 101) => (
							<SwiperSlide key={cnt} className="finished-slide">
								<Link to={`/gallery/${obj.id}`}>
									<div className="finished-content-wrapper">
										<img src={obj.images[0]} alt={obj.title + " " + cnt++} className="finished-img" />
										<p className="finished-title">{obj.title}</p>
									</div>
								</Link>
							</SwiperSlide>
						))
					) : (
						<SwiperSlide className="finished-slide">Loading</SwiperSlide>
					)}
				</Swiper>
			</div>
		</div>
	)
}

export default FinishedHouses
