import React from "react"
import { Service } from "../../components"
import { MyContext } from "../../Context/Context"

import "./Services.scss"

import serviceImg1 from "../../img/service1.webp"

import serviceImg2 from "../../img/service2.jpg"
import serviceImg2Webp from "../../img/service2.webp"

import serviceImg3 from "../../img/service3.jpg"
import serviceImg3Webp from "../../img/service3.webp"

import okna from "../../img/okna.jpg"
import oknaWebp from "../../img/okna.webp"

import dveri from "../../img/dveri.jpg"
import dveriWebp from "../../img/dveri.webp"

import decora from "../../img/decora.jpg"
import decoraWebp from "../../img/decora.webp"

import obsada from "../../img/obsada.jpg"
import obsadaWebp from "../../img/obsada.webp"

export const Services = () => {
	const { useWebP } = React.useContext(MyContext)
	return (
		<div className="services">
			<div className="services__wrapper">
				<h1 className="services__title">Услуги</h1>
				<h2 className="services__sub-title">КОМПАНИЯ ArteMadera ПРЕДОСТАВЛЯЕТ ПОЛНЫЙ КОМПЛЕКС УСЛУГ ПО СТРОИТЕЛЬСТВУ ДОМОВ</h2>

				<div className="services__blocks">
					{/* <Service header="Проектирование домов" img={serviceImg1} link="/services/designing" /> */}
					<Service header="Инженерные коммуникации" img={useWebP ? serviceImg2Webp : serviceImg2} link="https://artemadera.ru/ingeneriya/" />
					<Service header="Отделка деревянных домов" img={useWebP ? serviceImg3Webp : serviceImg3} link="https://artemadera.ru/" />
					<Service header="Обсада" img={useWebP ? obsadaWebp : obsada} link="https://artemadera.ru/obsada" />
					<Service header="Окна" img={useWebP ? oknaWebp : okna} link="https://artemadera.ru/okna" />
					<Service header="Двери" img={useWebP ? dveriWebp : dveri} link="https://artemadera.ru/dveri" />
					<Service header="Декоративные панели" img={useWebP ? decoraWebp : decora} link="https://artedecora.ru/" />
				</div>
			</div>
		</div>
	)
}

export default Services
