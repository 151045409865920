import React from "react"

import { MdMarkEmailRead, MdReportGmailerrorred } from "react-icons/md"

import "./PopupAnswer.scss"

export const PopupAnswer = (props) => {
	const popupRef = React.useRef(null)

	React.useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				props.onClosePopup()
			}
		}

		document.addEventListener("mousedown", handleClickOutside)

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [])
	return (
		<div className="popupAnswer">
			<div ref={popupRef} className="popupAnswer__wrapper">
				<h2 className="popupAnswer__title">{props.text}</h2>
				<span>
					{props.text === "Ваше письмо отправлено!" ? (
						<MdMarkEmailRead className="text-5xl fill-colorMain mt-4" />
					) : (
						<MdReportGmailerrorred className="text-5xl fill-red-600 mt-4" />
					)}
				</span>
				<button className="popupAnswer__button" onClick={props.onClosePopup}>
					Закрыть окно
				</button>
			</div>
		</div>
	)
}

export default PopupAnswer
