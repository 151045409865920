import React from "react"
import "./BuildBlock.scss"
import { Link } from "react-router-dom"

export default function BuildBlock(props) {
	return (
		<Link className="build-block" to="/projects" onClick={props.onLinkClick}>
			<div className="build-block1 relative">
				<img src={props.img} alt={props.title} className="build-block__image" />
				<h1 className="build-block__title">{props.title}</h1>
				<div className="build-block__bar"></div>
			</div>
		</Link>
	)
}
{
	/* <picture>
					<source
						srcSet={props.imgWebP.srcSet}
						type="image/webp"
						sizes="(max-width: 760px) 300px, (max-width: 1025px) 400px, (max-width: 1199px) 500px, 100vw"
					/>
					<img src={props.img.src} srcSet={props.img.srcSet} loading="lazy" sizes="(max-width: 760px) 300px, (max-width: 1025px) 400px, (max-width: 1199px) 500px, 100vw" alt={props.title} className="build-block__image" />
				</picture> */
}
