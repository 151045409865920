import { configureStore } from "@reduxjs/toolkit"
import { houseReducer } from "./slices/house"
import { galleryReducer } from "./slices/gallery"

const store = configureStore({
	reducer: {
		houses: houseReducer,
		gallery: galleryReducer,
	},
})

export default store
