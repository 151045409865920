import React from "react"
import { fetchHouses } from "../../redux/slices/house"
import { useDispatch, useSelector } from "react-redux"
import { Filter, House, HouseSkeleton } from "../../components/index"

import "./Projects.scss"
import loadingGif from "../../img/loading.gif"
import { GiToggles } from "react-icons/gi"

export const Projects = () => {
	const [toggleFilter, setToggleFilter] = React.useState(false)
	const [filterData, setFilterData] = React.useState([])
	const [isHousesLoading, setHousesLoading] = React.useState(false)

	function handleFilterData(data) {
		setFilterData(data)
	}

	function onFilterLoad(bool) {
		setHousesLoading(bool)
	}

	const dispatch = useDispatch()
	const { houses } = useSelector((state) => state.houses)

	const reduxLoading = houses.status === "loading"

	React.useEffect(() => {
		setHousesLoading(reduxLoading)
	}, [reduxLoading])

	React.useEffect(() => {
		dispatch(fetchHouses())

		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<div className="projects__title">Проекты</div>
			<div className="projects__show-filter-btn" onClick={() => setToggleFilter(!toggleFilter)}>
				<span className=" border-b border-dotted border-colorMain">Фильтр</span>
				{/* <span className="animate-ping absolute left-6 h-4 w-4 rounded-full bg-yellow-400 opacity-75"></span> */}
				<GiToggles className="fill-colorMain" size={20} />
			</div>

			<div className="projects__wrapper">
				<div className={`projects__filter`}>
					<Filter onFilterData={handleFilterData} onFilterLoad={onFilterLoad} isLoading={isHousesLoading} />
				</div>

				<div className={`projects__filter_mobile ${toggleFilter ? "absolute scale-y-100" : "absolute scale-y-0"}`}>
					<Filter onFilterData={handleFilterData} onFilterLoad={onFilterLoad} isLoading={isHousesLoading} />
				</div>

				<div className="projects__houses">
					<div className="projects__houses-title">
						{isHousesLoading ? (
							<img src={loadingGif} alt="Loading" width={30} h={30} className="mx-2" />
						) : !filterData.length ? (
							"Нет подходящих проектов"
						) : filterData.length === 0 ? (
							""
						) : filterData.length === 1 ? (
							`Подобран ${filterData.length} Проект`
						) : filterData.length < 5 ? (
							`Подобрано ${filterData.length} Проекта`
						) : (
							`Подобрано ${filterData.length} Проектов`
						)}
					</div>
					<div className="projects__block">
						{isHousesLoading ? (
							<>
								<HouseSkeleton />
								<HouseSkeleton />
								<HouseSkeleton />
								<HouseSkeleton />
							</>
						) : (
							filterData.map((obj, index) => (
								<House
									isLoading={isHousesLoading}
									key={index}
									id={obj.id}
									title={obj.title}
									houseType={obj.houseType}
									viewsCount={obj.viewsCount}
									images={obj.images}
									area={obj.area}
									wallMaterial={obj.wallMaterial}
									floorsCount={obj.floorsCount}
									roomsCount={obj.roomsCount}
									toiltesCount={obj.toiltesCount}
									price={obj.price}
									firstFloorArea={obj.firstFloorArea}
									firstFloorImage={obj.firstFloorImage}
									secondFloorArea={obj.secondFloorArea}
									secondFloorImage={obj.secondFloorImage}
									hasMansarda={obj.hasMassandra}
									likeCount={obj.likeCount}
									likes={obj.likes}
								/>
							))
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default React.memo(Projects)
