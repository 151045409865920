import React from "react"
import { Link } from "react-router-dom"
import CallbackForm from "../CallbackForm/CallbackForm"
import { MyContext } from "../../Context/Context"
import MobileMenu from "../MobileMenu/MobileMenu"

import "./Header.scss"

import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai"
import Logo from "../../img/logo.png"
import wooden from "../../img/h-wooden.png"
import stone from "../../img/h-stone.png"
import banya from "../../img/h-banya.png"
import combined from "../../img/h-combined.png"
import typical from "../../img/h-typical.png"

export const Header = () => {
	const [toggleMenu, setToggleMenu] = React.useState(false)
	const [isFormOpen, setIsFormOpen] = React.useState(false)
	const {
		updateFilterValue,
		childrenSetHouseType,
		childrenSetCategory,
		childrenSetMaterials,
		childrenSetArea,
		childrenSetOneFloor,
		childrenSetTwoFloors,
	} = React.useContext(MyContext)

	const woodenType = () => {
		childrenSetHouseType(["Деревянный дом"])
		updateFilterValue({ houseType: "Деревянный дом" })
	}

	const stoneType = () => {
		childrenSetHouseType(["Каменный дом"])
		updateFilterValue({ houseType: "Каменный дом" })
	}

	const combinedType = () => {
		childrenSetHouseType(["Комбинированный дом"])
		updateFilterValue({ houseType: "Комбинированный дом" })
	}

	const bathType = () => {
		childrenSetHouseType(["Баня"])
		updateFilterValue({ houseType: "Баня" })
	}

	const typicalCategory = () => {
		childrenSetCategory(["Типовые проекты"])
		updateFilterValue({ category: "Типовые проекты" })
	}

	const clearFilter = () => {
		childrenSetArea(25, 570)
		childrenSetMaterials([])
		childrenSetCategory([])
		childrenSetHouseType([])
		childrenSetOneFloor(0)
		childrenSetTwoFloors(0)
		updateFilterValue({})
	}

	const handleOpenForm = () => {
		setIsFormOpen(true)
		document.body.style.overflow = "hidden"
	}

	const handleCloseForm = () => {
		setIsFormOpen(false)
		document.body.style.overflow = "auto"
	}

	return (
		<header id="header">
			<nav>
				<span>
					<Link to="/">
						<img src={Logo} alt="Logo" className="h-[60px] sm:h-[80px] xl:h-[120px]" />
					</Link>
				</span>

				<ul className="nav-wrapper">
					<li className="nav-links">
						<a className="nav-link" href="/projects" onClick={clearFilter}>
							Проекты
						</a>

						<div className="submenu-lg">
							<div className="submenu-lg__item">
								<a onClick={woodenType} href="/projects" className="nav-link relative">
									<img src={wooden} alt="Деревянные дома" className="submenu-lg__image" />
									<p className="submenu-lg__text">Деревянные дома</p>
								</a>
							</div>

							<div className="submenu-lg__item">
								<a onClick={stoneType} href="/projects" className="nav-link relative">
									<img src={stone} alt="Каменные дома" className="submenu-lg__image" />
									<p className="submenu-lg__text">Каменные дома</p>
								</a>
							</div>

							<div className="submenu-lg__item">
								<a onClick={bathType} href="/projects" className="nav-link relative">
									<img src={banya} alt="Бани" className="submenu-lg__image" />
									<p className="submenu-lg__text">Бани</p>
								</a>
							</div>

							<div className="submenu-lg__item">
								<a onClick={combinedType} href="/projects" className="nav-link relative">
									<img src={combined} alt="Комбинированные" className="submenu-lg__image" />
									<p className="submenu-lg__text">Комбинированные</p>
								</a>
							</div>

							<div className="submenu-lg__item">
								<a onClick={typicalCategory} href="/projects" className="nav-link relative">
									<img src={typical} alt="Типовые проекты" className="submenu-lg__image" />
									<p className="submenu-lg__text">Типовые проекты</p>
								</a>
							</div>
						</div>
					</li>

					<li className="nav-links relative">
						<Link className="nav-link" to="/services">
							Услуги
						</Link>

						<div className="submenu">
							{/* <div className="submenu__item">
								<Link to="/services/designing">Проектирование домов</Link>
							</div> */}

							<div className="submenu__item">
								<Link to="https://artemadera.ru/ingeneriya/" target="_blank">
									Инженерные коммуникации
								</Link>
							</div>

							<div className="submenu__item">
								<Link to="https://artemadera.ru/" target="_blank">
									Отделка деревянных домов
								</Link>
							</div>

							<div className="submenu__item">
								<Link to="https://artemadera.ru/obsada/" target="_blank">
									Обсада
								</Link>
							</div>

							<div className="submenu__item">
								<Link to="https://artemadera.ru/okna/" target="_blank">
									Окна
								</Link>
							</div>

							<div className="submenu__item">
								<Link to="https://artemadera.ru/dveri/" target="_blank">
									Двери
								</Link>
							</div>

							<div className="submenu__item">
								<Link to="https://artedecora.ru/" target="_blank">
									Декоративные панели
								</Link>
							</div>
						</div>
					</li>

					<li className="nav-links relative">
						<Link className="nav-link" to="/gallery">
							Галерея
						</Link>

						{/* <div className="submenu">
							<div className="submenu__item">
								<Link to="#">Фотогалерея</Link>
							</div>

							<div className="submenu__item">
								<Link to="#">Карта объектов</Link>
							</div>
						</div> */}
					</li>

					<li className="nav-links relative">
						<Link className="nav-link" to="/blog">
							Блог
						</Link>
					</li>

					<li className="nav-links relative">
						<Link className="nav-link" to="#">
							О компании
						</Link>

						<div className="submenu">
							<div className="submenu__item">
								<Link to="#">О нас</Link>
							</div>

							{/* <div className="submenu__item">
								<Link to="#">Партнёры</Link>
							</div>

							<div className="submenu__item">
								<Link to="#">Документы</Link>
							</div> */}
						</div>
					</li>

					<li className="nav-links relative">
						<Link className="nav-link" to="/contacts">
							Контакты
						</Link>
					</li>
				</ul>

				<div className="nav-action">
					<ul className="nav-action-wrapper">
						<li className="nav-action-link">
							<Link to="tel:+74950050145">+7(495) 005-01-45</Link>
						</li>
						<li className="nav-action-link">
							<Link to="mailto:info@artemadera.ru">info@artemadera.ru</Link>
						</li>
						<li className="get-call-form" onClick={handleOpenForm}>
							заказать звонок
						</li>
					</ul>
					<div className="nav-action-contact">
						<a href="tel:+74950050145">
							<AiOutlinePhone size={30} className="fill-colorMain" />
						</a>
						<a href="mailto:info@artemadera.ru">
							<AiOutlineMail size={30} className="fill-colorMain" />
						</a>
					</div>
					<div className="nav-mobile">
						{toggleMenu ? (
							<RiCloseLine className="fill-colorDarkGray" size={30} onClick={() => setToggleMenu(false)} />
						) : (
							<RiMenu3Line className="fill-colorDarkGray" size={30} onClick={() => setToggleMenu(true)} />
						)}

						<MobileMenu
							toggleMenu={toggleMenu}
							woodenType={woodenType}
							stoneType={stoneType}
							combinedType={combinedType}
							bathType={bathType}
							typicalCategory={typicalCategory}
							handleOpenForm={handleOpenForm}
							clearFilter={clearFilter}
						/>
					</div>
				</div>
			</nav>

			{isFormOpen && <CallbackForm formCloseClick={handleCloseForm} />}
		</header>
	)
}

export default Header
