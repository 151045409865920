import React from "react"
import { Link } from "react-router-dom"

import "./GalleryBlock.scss"

export const GalleryBlock = (props) => {
	return (
		<div className="gallery-block" key={props.key1}>
			<Link to={`/gallery/${props.id}`}>
				<div className="gallery-block__image">
					<img src={props.images[0]} alt={props.title} className="gallery-block-img" loading="lazy" />
				</div>
				<h2 className="gallery-block__title">{props.title}</h2>
			</Link>
		</div>
	)
}

export default GalleryBlock
