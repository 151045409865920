import React, { useState } from "react"

export const HouseTabs = (props) => {
	const [activeTabIndex, setActiveTabIndex] = useState(0)

	const tabs = [
		{
			title: "Планировка дома",
			content: (
				<div className="full__floors floors">
					<div className="floors__first floor">
						<span className="floors__text">Площадь 1 этажа</span>
						<span className="floors__spec">
							{props.planArea1} м <sup>2</sup>
						</span>
						<img
							src={props.planImage1}
							alt="План первого этажа"
							className="floors__img"
							onClick={() => {
								props.onPlanImageClick(0)
							}}
						/>
					</div>

					{props.planArea2 === 0 ? (
						""
					) : (
						<div className="floors__second floor">
							<span className="floors__text">Площадь 2 этажа</span>
							<span className="floors__spec">
								{props.planArea2} м <sup>2</sup>
							</span>
							<img
								src={props.planImage2}
								alt="План второго этажа"
								className="floors__img"
								onClick={() => {
									props.onPlanImageClick(1)
								}}
							/>
						</div>
					)}
				</div>
			),
		},
		props.projDesc
			? {
					title: "Описание",
					content: <div className="text-xs md:text-sm px-2 md:px-0 w-full md:w-2/3 lg:w-1/2">{props.projDesc}</div>,
			  }
			: null,
	].filter(Boolean)

	const handleTabClick = (index) => {
		setActiveTabIndex(index)
	}

	return (
		<div className="houseTabs">
			<div className="houseTabs__wrapper flex justify-start items-start gap-2 xl:block">
				{tabs.map((tab, index) => (
					<button
						key={index}
						type="button"
						onClick={() => handleTabClick(index)}
						className={`${index === activeTabIndex ? "bg-colorMain" : "bg-colorDarkGray"} 
						py-2 px-3 lg:px-4 mr-2 w-[150px] nik:w-[200px] uppercase text-white text-[10px] nik:text-base 
						font-semibold hover:bg-colorMain transition-all duration-300 ease-linear`}
					>
						{tab.title}
					</button>
				))}
			</div>

			<div className="houseTabs__desc py-8">{tabs[activeTabIndex].content}</div>
		</div>
	)
}

export default HouseTabs
