import React from "react"
import { Link } from "react-router-dom"

import { MyContext } from "../../Context/Context"

import "./Queries.scss"

import svgQuery1 from "../../img/query-1.svg"
import pngQuery2 from "../../img/Query2.png"
import pngQuery3 from "../../img/Query3.png"

export const Queries = ({ material }) => {
	const { updateFilterValue, childrenSetMaterials, childrenSetArea, childrenSetHouseType } = React.useContext(MyContext)

	const [matValue, setMatValue] = React.useState(true)

	React.useEffect(() => {
		setMatValue(material.length > 0 ? material[0] : material)
	}, [material])

	const lessThen100 = () => {
		childrenSetHouseType([])
		childrenSetArea(25, 100)
		updateFilterValue({ minArea: 25, maxArea: 100 })
	}

	const moreThen150 = () => {
		childrenSetHouseType([])
		childrenSetArea(150, 575)
		updateFilterValue({ minArea: 150, maxArea: 575 })
	}

	const materialCurrent = () => {
		childrenSetHouseType([])
		childrenSetMaterials([matValue])
		updateFilterValue({ materials: matValue, minArea: 25, maxArea: 570 })
	}

	return (
		<div className="queries">
			<h1 className="queries__title">Смотрите также</h1>
			<div className="queries__wrapper">
				<div>
					<Link to="/projects" onClick={lessThen100} className="block">
						<div className="block__image">
							<img src={pngQuery3} alt="Проекты до 100м" className="block-image" />
						</div>
						<p>
							Проекты домов до 100 м<sup>2</sup>
						</p>
					</Link>
				</div>

				<div>
					<Link to="/projects" onClick={moreThen150} className="block">
						<div className="block__image">
							<img src={pngQuery2} alt="Проекты от 150м" className="block-image" />
						</div>
						<p>
							Проекты домов от 150 м<sup>2</sup>
						</p>
					</Link>
				</div>

				<div>
					<Link to="/projects" onClick={materialCurrent} className="block">
						<div className="block__image">
							<img src={svgQuery1} alt="Проекты до 150м" className="block-image" />
						</div>
						<p>Проекты {material.length > 0 ? material[0] : material}</p>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Queries
