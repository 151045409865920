import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Autoplay, Pagination } from "swiper"
import { Link } from "react-router-dom"

import "swiper/scss"
import "swiper/scss/navigation"
import "swiper/scss/pagination"
import "swiper/scss/autoplay"
import "./SwiperLarge.scss"

export const SwiperLarge = () => {
	return (
		<section className="swiper-section">
			<Swiper
				style={{
					"--swiper-navigation-color": "#fff",
					"--swiper-pagination-color": "#fff",
				}}
				modules={[Navigation, Autoplay, Pagination]}
				className="swiper-lg"
				slidesPerView={1}
				speed={500}
				loop={true}
				navigation
				pagination={{ clickable: true }}
			>
				<SwiperSlide className="slider-slide-1">
					<div className="text-wrapper">
						<div className="flex items-center justify-start relative">
							<div className="rotated-text">НОВИНКА</div>
							<div className="title">
								<div>Серийный</div> <div className="mt-0 sm:mt-2">каркасный барнхаус</div>
							</div>
						</div>
						<Link to="/projects/Wooden-House-PDBukKbr-Bh" className="slide-btn">
							Смотреть проект
						</Link>
					</div>
				</SwiperSlide>
			</Swiper>
		</section>
	)
}

export default SwiperLarge
