import React from 'react'
import { Service } from "../../components"

import './Designing.scss'

import designImg1 from '../../img/designing1.jpg'
import designImg2 from '../../img/designing2.webp'

export const Designing = () => {
  return (
    <div className="designing">
			<div className="designing__wrapper container py-4 mx-auto">
				<h1 className="designing__title">Услуги</h1>
				<h2 className="designing__sub-title">
					КОМПАНИЯ ArteMadera ПРЕДОСТАВЛЯЕТ ПОЛНЫЙ КОМПЛЕКС УСЛУГ ПО СТРОИТЕЛЬСТВУ ДОМОВ
				</h2>

				<div className="designing__blocks">
					<Service header="Эскизное проектирование" img={designImg1} link="/services/designing/sketch-designing" />
					<Service header="Рабочий проект" img={designImg2} link="/services/designing/working-draft" />
				</div>
			</div>
		</div>
  )
}

export default Designing