import React from "react"
import FooterLogo from "../../img/footer-logo.png"
import { Link } from "react-router-dom"
import { MyContext } from "../../Context/Context"

import "./Footer.scss"

import svgVK from "../../img/vkcom.svg"
import svgYT from "../../img/youtube.svg"
import svgInst from "../../img/instagram.svg"

export const Footer = () => {
	const { filterValue, updateFilterValue } = React.useContext(MyContext)
	const { houseType, childrenSetHouseType } = React.useContext(MyContext)
	const { category, childrenSetCategory } = React.useContext(MyContext)

	const woodenType = () => {
		updateFilterValue({})
		childrenSetHouseType({})
		updateFilterValue({ houseType: "Деревянный дом" })
	}

	const stoneType = () => {
		updateFilterValue({})
		childrenSetHouseType({})
		updateFilterValue({ houseType: "Каменный дом" })
	}

	const combinedType = () => {
		updateFilterValue({})
		childrenSetHouseType({})
		updateFilterValue({ houseType: "Комбинированный дом" })
	}

	const bathType = () => {
		updateFilterValue({})
		childrenSetHouseType({})
		updateFilterValue({ houseType: "Баня" })
	}

	const typicalCategory = () => {
		updateFilterValue({})
		childrenSetCategory({})
		updateFilterValue({ category: "Типовые проекты" })
	}

	return (
		<footer className="footer">
			<div className="footer__wrapper">

				<div className="footer__content content-float">
					<div className="footer__logo">
						<Link className="max-w-max flex w-full h-[120px]" to="/">
							<img src={FooterLogo} alt="Logo" className="logo-f" />
						</Link>
					</div>
					<div className="footer__major-text">
						&copy; 2021-2023 Все права защищены
						<div className="footer__major-text_terms">
							<Link to="/obrabotka-personalnykh-dannykh/">Обработка персональных данных</Link>
							<Link to="/politika-konfidentsialnosti/">Политика конфиденциальности</Link>
						</div>
					</div>
				</div>
				
				<div className="footer__info">
					<div className="footer__content">
						<h2 className="footer__title">Каталог проектов</h2>
						<Link onClick={woodenType} to="/projects" className="footer__link">
							Деревянные дома
						</Link>
						<Link onClick={stoneType} to="/projects" className="footer__link">
							Каменные дома
						</Link>
						<Link onClick={bathType} to="/projects" className="footer__link">
							Бани
						</Link>
						<Link onClick={combinedType} to="/projects" className="footer__link">
							Комбинированные дома
						</Link>
						<Link onClick={typicalCategory} to="/projects" className="footer__link">
							Типовые проекты
						</Link>
					</div>

					<div className="footer__content">
						<h2 className="footer__title">Услуги</h2>

						<Link to="https://artemadera.ru/ingeneriya/" className="footer__link">
							Инженерные коммуникации
						</Link>
						<Link to="https://artemadera.ru/" className="footer__link">
							Отделка деревянных домов
						</Link>
					</div>

					<div className="footer__content">
						<h2 className="footer__title">О компании</h2>

						<Link to="#" className="footer__link">
							О нас
						</Link>
						<Link to="#" className="footer__link">
							Партнеры
						</Link>
						<Link to="#" className="footer__link">
							Документы
						</Link>
						<Link to="/gallery" className="footer__link">
							Галерея
						</Link>
						<Link to="/blog" className="footer__link">
							Блог
						</Link>
						<Link to="/contacts" className="footer__link">
							Контакты
						</Link>
					</div>

					<div className="footer__content footer__content_many">
						<div className="footer__contacts">
							<h2 className="footer__title">Контакты</h2>
							<Link to="tel:+74950050145" className="footer__link tel-link">
								+7(495) 005-01-45
							</Link>
							<Link to="mailto:info@artemadera.ru" className="footer__link email-link">
								info@artemadera.ru
							</Link>
						</div>

						<div className="footer__contacts">
							<h2 className="footer__title">Наш офис</h2>
							<p className="footer__major-text adress">г. Москва Метро ВДНХ, Ярославская улица, д. 8, корп. 6, Офис 220</p>
						</div>
					</div>
				</div>

				<div className="footer__bot bot">
					<div className="bot__copy">
						Вся представленная на сайте информация носит информационный характер и ни при каких условиях не является публичной офертой, определяемой
						положениями Статьи 437(2) Гражданского кодекса РФ.
					</div>
					<div className="bot__social">
						<div>
							<img src={svgVK} alt="VK" className="social-svg" />
						</div>
						<div>
							<img src={svgYT} alt="YT" className="social-svg" />
						</div>
						<div>
							<img src={svgInst} alt="Inst" className="social-svg" />
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
