import React from "react"
import InputMask from "react-input-mask"
import { Link } from "react-router-dom"
import axios from "../../axios"
import PopupAnswer from "../PopupAnswer/PopupAnswer"

import { MdArrowRightAlt } from "react-icons/md"

import "./ComForm.scss"

export const ComForm = () => {
	const [name, setName] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [phone, setPhone] = React.useState("")
	const [unmaskedPhone, setUnmaskedPhone] = React.useState("")
	const [nameError, setNameError] = React.useState("")
	const [phoneError, setPhoneError] = React.useState("")
	const [emailError, setEmailError] = React.useState("")
	const [disableButton, setDisableButton] = React.useState(false)
	const [serverResponse, setServerResponse] = React.useState("")
	const [isPopupOpen, setIsPopupOpen] = React.useState(false)

	const validateName = () => {
		if (name === "") {
			setNameError("Пожалуйста, введите ваше имя")
		} else {
			setNameError("")
		}
	}

	const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu

	const validateEmail = () => {
		if (email.length > 1) {
			const emailTest = EMAIL_REGEXP.test(email)
			!emailTest && setEmailError("Неправильный ввод почты")
			emailTest && setEmailError("")
		} else setEmailError("")
	}

	React.useEffect(() => {
		setUnmaskedPhone(phone.replace(/\s|\(|\)/g, ""))
	}, [phone])

	const validatePhone = () => {
		if (!unmaskedPhone) {
			setPhoneError("Пожалуйста, введите ваш номер телефона")
		} else if (unmaskedPhone.length !== 12) {
			setPhoneError("Неверно введен номер")
		} else {
			setPhoneError("")
		}
	}

	//Отправка данных формы, с дальнейшей проверкой на сервере
	const handleSubmit = async (e) => {
		e.preventDefault()
		validateName()
		validatePhone()
		validateEmail()

		const formData = {
			name: name,
			phone: phone,
			email: email,
		}

		// Выполнение HTTP-запроса для отправки данных на сервер
		const { data } = await axios.post("/form/comForm", formData)
		setServerResponse(data)
		setIsPopupOpen(true)
		document.body.style.overflow = "hidden"
	}

	//Закрыть попап
	const handleClosePopup = () => {
		setIsPopupOpen(false)
		document.body.style.overflow = "auto"
	}

	const handleCheckPolicy = (event) => {
		event.checked = !event.checked

		const isChecked = event.target.checked
		setDisableButton(!isChecked)
	}
	return (
		<div>
			<form onSubmit={handleSubmit} className="comForm" id="comForm">
				<div className="comForm__wrapper">
					<div className="comForm__inputs">
						<input
							type="text"
							name="fio"
							id="fio"
							className="comForm__input"
							onChange={(e) => {
								setName(e.target.value)
							}}
							placeholder="Имя Фамилия"
						/>
						{nameError && <span className="error">{nameError}</span>}

						<InputMask
							mask="+7 (999) 999 99 99"
							placeholder="+7 (123) 456 78 90"
							type="tel"
							name="tel"
							id="tel"
							maskChar={" "}
							className="comForm__input"
							onChange={(e) => {
								setPhone(e.target.value)
							}}
						/>
						{phoneError && <span className="error">{phoneError}</span>}

						<input
							type="text"
							name="email"
							id="email"
							className="comForm__input"
							onChange={(e) => {
								setEmail(e.target.value)
							}}
							placeholder="example@mail.ru"
						/>
						{emailError && <span className="error">{emailError}</span>}
					</div>

					<button disabled={disableButton} type="submit" className="comForm__btn bg-colorMain flex justify-center items-center">
						<span>Позвоните мне</span> <MdArrowRightAlt className="text-xl lg:text-3xl ml-2" />
					</button>

					<div className="comForm__check">
						<input type="checkbox" name="policy" id="policy" defaultChecked onChange={handleCheckPolicy} className="comForm__policy" />
						<label className="policy-label" htmlFor="policy">
							{" "}
							Я согласен на
							<Link to='/obrabotka-personalnykh-dannykh/' className="underline"> обработку персональных данных</Link>
						</label>
					</div>

					{isPopupOpen && <PopupAnswer text={serverResponse} onClosePopup={handleClosePopup} />}
				</div>
			</form>
		</div>
	)
}

export default ComForm
