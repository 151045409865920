import React from "react"
import { MyContext } from "../../Context/Context"

import "./blogs.scss"

import blogImg2 from "../../img/article2.jpg"
import blogImg2Webp from "../../img/article2.webp"

export const EkoDoma = () => {
	const { useWebP } = React.useContext(MyContext)

	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="blogs container mx-auto px-8">
			<div className="blogs__wrapper">
				<h1 className="blogs__title">ЭКОЛОГИЧНОСТЬ ДОМОВ ИЗ КЛЕЕНОГО БРУСА: МИФЫ И РЕАЛЬНОСТЬ</h1>

				<h2 className="blogs__sub-title blogs__sub-title_italic ">Мифы про опасность клеенного бруса.</h2>

				<p className="blogs__text">
					<img src={useWebP ? blogImg2Webp : blogImg2} alt="ЭКОЛОГИЧНОСТЬ ДОМОВ ИЗ КЛЕЕНОГО БРУСА: МИФЫ И РЕАЛЬНОСТЬ" className="image-2" />
					Безопасен ли клеенный брус? Или есть риски, о которых любят заявлять компании которые строят дома по другим технологиям. Согласно
					международному стандарту EcoMaterial клеенный брус входит в тройку самых экологичных материалов. Как такой впечатляющий результат
					достигается?
				</p>

				<p className="blogs__text">
					Итак, давайте разбираться, почему клеенный брус считается материалом бизнес-класса и причем тут экологичность? Учитывая современный уровень
					загрязнения в таких больших городах, как Киев, проблем в сфере экологии и жизни в городе хватает. С клеенным брусом таких проблем нет. Но
					откуда возникает молва, что клеенный брус не безопасен и другие мифы, связанные с ним?
				</p>

				<p className="blogs__text">
					К сожалению это происходит потому что, нередко компании, строящие другие виды деревянных домов занимаются откровенной дезинформацией,
					используя её, как метод конкурентной борьбы, играя на страхах и неосведомлённости людей, которые выбирают из чего строить дом. Это
					неудивительно, ведь клеенный брус является эталоном по всем ключевым показатель и соответственно обходит по ним другие виды материалов для
					деревостроительстве. Его свойства позволяют без ущерба качеству использовать в строительстве только экологичные вспомогательные строительные
					материалы и технологии.
				</p>

				<p className="blogs__text">
					Вторичные экологичные материалы отлично сочетаются с клеенным брусом. Речь идёт об известных своей безопасностью материалах, таких как,
					антисептики из льяного масла, акриловые герметики, пчелиный воск, ляной утеплитель, который не влияет на усадку, а так же славится помимо
					экологичности, своими высокими показателями теплоэффективности. Но стоит заметить, что приведенные выше качества материалов достигаются
					только при условии, что строительством и непосредственно выполнением работ будут заниматься специалисты высокого уровня.
				</p>

				<p className="blogs__text">
					Существует много технических тонкостей, каждое действие строительной бригады имеет важность. Кстати ещё стоит сказать про ещё один
					строительный материал, про который любят говорить противники клеенного бруса - клей, который применяется на этапе производства деталей из
					дерева. Вопреки возможным домыслам и противникам домов из клеенного бруса, является полностью безопасными. Можно не углубляться в
					технологические подробности и просто сказать, что в таких высоко развитых странах как Канада, Финляндия, Япония, Швеция и тд дома из
					клеенного бруса давно является частью культуры. Жители в этих странах не задаются вопросами про экологичность клеенного бруса. Не просто
					высокие, а жесткие стандарты качества в этих странах позволяют потребителям даже не задумываться о токсичности клея, радиоактивности
					клеенного бруса и прочих забавных моментов.
				</p>
			</div>
		</div>
	)
}

export default EkoDoma
