import React from 'react'
import { Link } from 'react-router-dom'

import svgVK from '../../img/vkcom.svg'
import svgYT from '../../img/youtube.svg'
import svgInst from '../../img/instagram.svg'

import './Socials.scss'

export const Socials = () => {
  return (
    <div className='socials'>
        <h1 className="socials__title">МЫ В СОЦСЕТЯХ</h1>
        <h2 className="socials__sub-title">Присоединяйтесь к нам! Мы ведем активную деятельность в социальных сетях.</h2>
        <div className="socials__wrapper">
                <Link to='https://www.youtube.com/channel/UCvkV8COCD6LZFCNMHavIttA/featured' target='_blank' className='socials__link'>
                    <img src={svgVK} className='socials__img' />
                </Link>

                <Link to='https://vk.com/artemadera' target='_blank' className='socials__link'>
                    <img src={svgYT} className='socials__img' />
                </Link>
                
                <Link to='https://instagram.com/artemadera.msk?igshid=YmMyMTA2M2Y=' target='_blank' className='socials__link'>
                    <img src={svgInst} className='socials__img' />
                </Link>
        </div>
    </div>
  )
}

export default Socials