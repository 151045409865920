import React from "react"

import "./style.scss"

export const Politika = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className="terms">
			<h2 className="terms__title">Политика конфиденциальности</h2>
			<ol>
				<li>
					<div className="terms__header">
						1. <b>Общие положения</b>
					</div>
					<div>
						1.1 ArteMaderaStroy (далее по тексту – Оператор) ставит соблюдение прав и свобод граждан одним из важнейших условий осуществления своей
						деятельности.
					</div>
					<div>
						1.2 Политика Оператора в отношении обработки персональных данных (далее по тексту — Политика) применяется ко всей информации, которую
						Оператор может получить о посетителях веб-сайта https://artemaderastroy.ru. Персональные данные обрабатывается в соответствии с ФЗ «О
						персональных данных» № 152-ФЗ.
					</div>
				</li>
				<li>
					<div className="terms__header">
						2. <b>Основные понятия, используемые в Политике:</b>
					</div>
					<div>
						2.1 Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность
						в сети интернет по сетевому адресу https://artemaderastroy.ru;
					</div>
					<div>2.2 Пользователь – любой посетитель веб-сайта https://artemaderastroy.ru;</div>
					<div>2.3 Персональные данные – любая информация, относящаяся к Пользователю веб-сайта https://artemaderastroy.ru;</div>
					<div>
						2.4 Обработка персональных данных — любое действие с персональными данными, совершаемые с использованием ЭВМ, равно как и без их
						использования;
					</div>
					<div>
						2.5 Обезличивание персональных данных – действия, результатом которых является невозможность без использования дополнительной информации
						определить принадлежность персональных данных конкретному Пользователю или лицу;
					</div>
					<div>
						2.6 Распространение персональных данных – любые действия, результатом которых является раскрытие персональных данных неопределенному кругу
						лиц;
					</div>
					<div>
						2.7 Предоставление персональных данных – любые действия, результатом которых является раскрытие персональных данных определенному кругу
						лиц;
					</div>
					<div>
						2.8 Уничтожение персональных данных – любые действия, результатом которых является безвозвратное уничтожение персональных на ЭВМ или любых
						других носителях.
					</div>
				</li>
				<li>
					<div className="terms__header">
						3. <b>Оператор может обрабатывать следующие персональные данные Пользователя:</b>
					</div>
					<div>3.1 Список персональных данных, которые обрабатывает оператор: фамилия, имя, отчество, номер телефона, адрес электронной почты.</div>
					<div>
						3.2. Кроме того, на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
						интернет-статистики (Яндекс Метрика, Гугл Аналитика и других).
					</div>
				</li>
				<li>
					<div className="terms__header">
						4. <b>Цели обработки персональных данных</b>
					</div>
					<div>
						4.1 Персональные данные пользователя — фамилия, имя, отчество, номер телефона, адрес электронной почты — обрабатываются со следующей
						целью: Уточнение деталей и отправка заказа клиенту. Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах,
						специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив
						Оператору письмо на адрес info@artemaderastroy.ru
					</div>
					<div>
						4.2 Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях
						Пользователей на сайте, улучшения качества сайта и его содержания.
					</div>
				</li>
				<li>
					<div className="terms__header">
						5. <b>Правовые основания обработки персональных данных</b>
					</div>
					<div>
						5.1 Оператор обрабатывает персональные данные Пользователя только в случае их отправки Пользователем через формы, расположенные на
						веб-сайте https://artemaderastroy.ru. Отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной
						Политикой.
					</div>
					<div>
						5.2 Оператор обрабатывает обезличенные данные о Пользователе в случае, если Пользователь разрешил это в настройках браузера (включено
						сохранение файлов «cookie» и использование технологии JavaScript).
					</div>
				</li>
				<li>
					<div className="terms__header">
						6. <b>Порядок сбора, хранения, передачи и других видов обработки персональных данных</b>
					</div>
					<div>
						6.1 Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным
						неуполномоченных лиц.
					</div>
					<div>
						6.2 Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с
						исполнением действующего законодательства.
					</div>
					<div>
						6.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их, направив Оператору уведомление с помощью
						электронной почты на электронный адрес Оператора info@artemaderastroy.ru, либо на почтовый адрес Оператора Москва, улица Большая почтовая,
						д. 71, офис 203, с пометкой «Актуализация персональных данных».
					</div>
					<div>
						6.4 Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку
						персональных данных, направив Оператору уведомление с помощью электронной почты на электронный адрес Оператора info@artemaderastroy.ru,
						либо на почтовый адрес Оператора г. Москва Метро ВДНХ, Ярославская улица, д. 8, корп. 6, Офис 220, с пометкой «Отзыв согласия на обработку
						персональных данных».
					</div>
				</li>
				<li>
					<div className="terms__header">
						7. <b>Заключительные положения</b>
					</div>
					<div>
						7.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к
						Оператору с помощью электронной почты info@artemaderastroy.ru, либо на почтовый адрес Оператора г. Москва Метро ВДНХ, Ярославская улица,
						д. 8, корп. 6, Офис 220.
					</div>
					<div>
						7.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. В случае существенных изменений
						Пользователю может быть выслана информация на указанный им электронный адрес.
					</div>
				</li>
			</ol>
		</div>
	)
}

export default Politika
